import React ,{ useRef,useState } from 'react'
import { useLocation } from "react-router-dom";
import { components } from 'cng-web-lib'
import { Grid } from '@material-ui/core'
import pathMap from 'src/paths/pathMap'
import EhblHouseBillApiUrls from 'src/apiUrls/EhblHouseBillApiUrls'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import FormProperties from './FormProperties'

const { button: { CngButton }, form: { CngAddForm } } = components

function AddPage({ history, showNotification }) {
  const loc = useLocation()
  const [lookups, setLookups] = useState(null)

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function getCountryStateLabel(country, state) {
    if (!lookups || !country || !state) return state

    // Check if country code exists in the lookup
    if (Object.keys(lookups.countries).includes(country)) {
      const result = lookups.countryStates[country].find(
        (countryState) => countryState.intlcode === state
      )

      return result ? result.descriptionEn : state
    } else {
      return state
    }
  }

  const onSuccessCallback = useRef(() =>
    history.push(pathMap.EHBL_HOUSE_BILL_LIST_VIEW)
  )

  function handleSaveAndContinue() {
    onSuccessCallback.current = (e) => {
        history.push(`${pathMap.EHBL_HOUSE_BILL_PATH}/edit/${e.id}`)
    }
}

  let formikPropsData;
  let  cloneFromManifest;
  let manifestId;
  if(loc.state !== undefined) {
    formikPropsData = {
          ...FormProperties.formikProps,
          initialValues: FormProperties.toClientDataFormat(loc.state)
    }
    manifestId=loc.state.manifestId;
    cloneFromManifest=loc.state.cloneFromManifest;
  } else {
    formikPropsData = FormProperties.formikProps
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngAddForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          history={history}
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
              getLookupValue={getLookupValue}
              getCountryStateLabel={getCountryStateLabel}
              autoPopulateBusinessName
            />
          )}
          formikProps={formikPropsData}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
            url: EhblHouseBillApiUrls.POST,
            successRedirect: (cloneFromManifest!==null && cloneFromManifest!==undefined && cloneFromManifest=="Y") ? '/ehbl/manifest/edit/'+manifestId: pathMap.EHBL_HOUSE_BILL_LIST_VIEW
          }}
          renderButtonSection={(loading) => (
            <ButtonSection
                history={history}
                loading={loading}
                onSaveAndContinue={handleSaveAndContinue}
            />
        )}
        />
      </Grid>
    </Grid>
  )
}

export default AddPage

function ButtonSection(props) {
  const { history, loading, onSaveAndContinue } = props
  const [confirmDialog, setConfirmDialog] = useState(false)

  return (
      <>
          <Grid container justify='space-between' spacing={3}>
              <Grid item xs='auto'>
                  <CngButton color='secondary' disabled={loading} onClick={() => setConfirmDialog(true)} size='medium'>Discard</CngButton>
              </Grid>
              <Grid item xs='auto'>
                  <Grid container spacing={2}>
                      <Grid item xs='auto'>
                          <CngButton color='primary' disabled={loading} type='submit' onClick={onSaveAndContinue} size='medium'>Save</CngButton>
                      </Grid>
                  </Grid>
              </Grid>
          </Grid>
          <AlertDialog
              cancelLabel='Continue editing'
              confirmLabel='Yes, discard'
              open={confirmDialog}
              onClose={() => setConfirmDialog(false)}
              onCancel={() => setConfirmDialog(false)}
              onConfirm={() => history.push(pathMap.EHBL_HOUSE_BILL_LIST_VIEW)}
              title='Discard'
          >
              All progress in this session will be lost and cant't be restored. Are
              you sure about this?
          </AlertDialog>
      </>
  )
}
