import React, { useEffect, useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import EhblHouseBillKeys from 'src/constants/locale/key/EhblHouseBill'
import { Grid, CardContent, Typography, useTheme, Divider } from '@material-ui/core'
import {
  components
} from 'cng-web-lib'
import { useTranslation } from 'cng-web-lib'
import CngSection from '../../../components/cngcomponents/CngSection'
import ContainerDetailsTable from './ContainerDetailsTable'
import TradePartyTable from './TradePartyTable'
import ProductDetailsTable from './ProductDetailsTable'
import StatusBar from '../../../components/aciacehighway/StatusBar'
import CngField from '../../../components/cngcomponents/CngField'
import TradePartyDialog from './TradePartyDialog'
import ContainerDetailsDialog from './ContainerDetailsDialog'
import ProductDetailsDialog from './ProductDetailsDialog'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import ActivityLogs from './ActivityLogs'

const {
  form: {
    field: {
      CngTextField,
      CngDateField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  manifestId: "",
  corpid: "",
  movementType: "",
  indicatorMode: "",
  primaryCcn: "",
  carrierCode: "",
  ccn: "",
  portOfDischargeCode: "",
  portOfDestCode: "",
  messageFuncCode: "",
  consolidationInd: false,
  totalWeight: "",
  status: "",
  closeStatus: "",
  responseDatetime: "",
  serviceBureauId: "",
  upsClientId: "",
  userRole: "",
  email: "",
  mobileNo: "",
  totalWeightUom: "",
  shipperName: "",
  consigneeName: "",
  entryNo: "",
  amendmentCode: "",
  ucn: "",
  b2bComment: "",
  destSubLocCode: "",
  discSubLocCode: "",
  undgContactName: "",
  undgContactNo: "",
  specialInstruction: "",
  isDangerousGoods: false,
  hanInstruction: "",
  volume: "",
  volumeUom: "",
  containerizedInd: false,
  mfInd: "",
  mfDatetime: "",
  businessName: "",
  freightFwdCode: "",
  uniqueRefNo: "",
  clMsgInd: "",
  prevCcn: "",
  noticeReasonCode: "",
  houseRefNo: "",
  usPortOfExit: "",
  usPortOfExitDesc: "",
  frnPortOfLoading: "",
  frnPortOfLoadingDesc: "",
  remarks: "",
  printDate: "",
  accountOff: "",
  storageDate: "",
  partyId: "",
  submittedBy: "",
  submittedDate: "",
  templateName: "",
  modeOfSubmission: "",
  containerDetails: [],
  tradeParty: [],
  productDetails: [],
  statusNotifyParties: [],
  templateFlag: false
})

function HouseBillViewContent(props) {
  const { data, getLookupValue, getCountryStateLabel, disabled } = props
  const { translate } = useTranslation(Namespace.EHBL_HOUSE_BILL)
  const translatedTextsObject = makeTranslatedTextsObject()
  const theme = useTheme()

  const [viewTradePartyDialog, setViewTradePartyDialog] = useState({
    open: false,
    tradeParty: null
  })

  const [viewContainerDetailsDialog, setViewContainerDetailsDialog] = useState({
    open: false,
    containerDetails: null
  })

  const [viewProductDetailsDialog, setViewProductDetailsDialog] = useState({
    open: false,
    productDetails: null
  })

  useEffect(() => {
    console.log("trigger")
  }, [])

  function checkA8A() {

    let usPortOfExit = data.usPortOfExit
    if (usPortOfExit != null && usPortOfExit != undefined && usPortOfExit != "") {
      return true;
    }

    let frnPortOfLoading = data.frnPortOfLoading
    if (frnPortOfLoading != null && frnPortOfLoading != undefined && frnPortOfLoading != "") {
      return true;
    }

    let printDate = data.printDate
    if (printDate != null && printDate != undefined && printDate != "") {
      return true;
    }

    let accountOff = data.accountOff
    if (accountOff != null && accountOff != undefined && accountOff != "") {
      return true;
    }

    let storageDate = data.usPortOfExit
    if (storageDate != null && storageDate != undefined && storageDate != "") {
      return true;
    }

    return false;
  }

  function makeTranslatedTextsObject() {

    let ehblHouseBill = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TITLE
    )
    let manifestId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MANIFEST_ID
    )
    let corpid = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CORPID
    )
    let movementType = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MOVEMENT_TYPE
    )
    let indicatorMode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.INDICATOR_MODE
    )
    let primaryCcn = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PRIMARY_CCN
    )
    let carrierCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CARRIER_CODE
    )
    let ccn = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CCN
    )
    let portOfDischargeCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PORT_OF_DISCHARGE_CODE
    )
    let portOfDestCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PORT_OF_DEST_CODE
    )
    let messageFuncCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MESSAGE_FUNC_CODE
    )
    let consolidationInd = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CONSOLIDATION_IND
    )
    let totalWeight = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TOTAL_WEIGHT
    )
    let status = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.STATUS
    )
    let closeStatus = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CLOSE_STATUS
    )
    let responseDatetime = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.RESPONSE_DATETIME
    )
    let serviceBureauId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SERVICE_BUREAU_ID
    )
    let upsClientId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UPS_CLIENT_ID
    )
    let userRole = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.USER_ROLE
    )
    let email = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.EMAIL
    )
    let mobileNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MOBILE_NO
    )
    let totalWeightUom = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TOTAL_WEIGHT_UOM
    )
    let shipperName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SHIPPER_NAME
    )
    let consigneeName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CONSIGNEE_NAME
    )
    let entryNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ENTRY_NO
    )
    let amendmentCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.AMENDMENT_CODE
    )
    let ucn = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UCN
    )
    let b2bComment = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.B2B_COMMENT
    )
    let destSubLocCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.DEST_SUB_LOC_CODE
    )
    let discSubLocCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.DISC_SUB_LOC_CODE
    )
    let undgContactName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UNDG_CONTACT_NAME
    )
    let undgContactNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UNDG_CONTACT_NO
    )
    let specialInstruction = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SPECIAL_INSTRUCTION
    )
    let isDangerousGoods = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.IS_DANGEROUS_GOODS
    )
    let hanInstruction = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.HAN_INSTRUCTION
    )
    let volume = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.VOLUME
    )
    let volumeUom = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.VOLUME_UOM
    )
    let containerizedInd = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CONTAINERIZED_IND
    )
    let mfInd = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MF_IND
    )
    let mfDatetime = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MF_DATETIME
    )
    let businessName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.BUSINESS_NAME
    )
    let freightFwdCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.FREIGHT_FWD_CODE
    )
    let uniqueRefNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UNIQUE_REF_NO
    )
    let clMsgInd = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CL_MSG_IND
    )
    let prevCcn = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PREV_CCN
    )
    let noticeReasonCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.NOTICE_REASON_CODE
    )
    let houseRefNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.HOUSE_REF_NO
    )
    let usPortOfExit = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.US_PORT_OF_EXIT
    )
    let usPortOfExitDesc = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.US_PORT_OF_EXIT_DESC
    )
    let frnPortOfLoading = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.FRN_PORT_OF_LOADING
    )
    let frnPortOfLoadingDesc = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.FRN_PORT_OF_LOADING_DESC
    )
    let remarks = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.REMARKS
    )
    let printDate = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PRINT_DATE
    )
    let accountOff = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ACCOUNT_OFF
    )
    let storageDate = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.STORAGE_DATE
    )
    let partyId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PARTY_ID
    )
    let submittedBy = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SUBMITTED_BY
    )
    let submittedDate = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SUBMITTED_DATE
    )
    let templateName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TEMPLATE_NAME
    )
    let modeOfSubmission = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MODE_OF_SUBMISSION
    )
    let cargoHeader = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.CARGO_HEADER
    )

    let containerDetails = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.TITLE
    )
    let hblId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.HBL_ID
    )
    let mfId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.MF_ID
    )
    let equipmentId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.EQUIPMENT_ID
    )
    let equipmentType = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.EQUIPMENT_TYPE
    )
    let sealNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO
    )
    let tradeParty = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.TITLE
    )
    let partyName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.PARTY_NAME
    )
    let partyType = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.PARTY_TYPE
    )
    let division = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.DIVISION
    )
    let locPortCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.LOC_PORT_CODE
    )
    let poBoxNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.PO_BOX_NO
    )
    let address = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.ADDRESS
    )
    let city = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.CITY
    )
    let stateCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.STATE_CODE
    )
    let countryCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.COUNTRY_CODE
    )
    let postalCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.POSTAL_CODE
    )
    let contactName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.CONTACT_NAME
    )
    let telephoneNum = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.TELEPHONE_NUM
    )
    let snpType = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.SNP_TYPE
    )
    let snpUcn = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.SNP_UCN
    )
    let snpB2bComment = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.SNP_B2B_COMMENT
    )
    let snpIdentifierValue = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.SNP_IDENTIFIER_VALUE
    )

    let cargoDetails = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.CARGO_DETAILS
    )

    let productDetails = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.TITLE
    )
    let quantity = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.QUANTITY
    )
    let uom = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.UOM
    )
    let description = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.DESCRIPTION
    )
    let marksAndNumbers = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.MARKS_AND_NUMBERS
    )
    let hsCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.HS_CODE
    )
    let undgCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.UNDG_CODE
    )
    let weight = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.WEIGHT
    )
    let weightUom = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.WEIGHT_UOM
    )
    let statusNotifyParties = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.StatusNotifyParties.TITLE
    )
    let name = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.StatusNotifyParties.NAME
    )
    let snType = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.StatusNotifyParties.SN_TYPE
    )
    let hblCcn = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.StatusNotifyParties.HBL_CCN
    )
    let hpNum = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.StatusNotifyParties.HP_NUM
    )
    let module = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.StatusNotifyParties.MODULE
    )

    let printA8A = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PRINT_A8A
    )

    let printA8AFooterText = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PRINT_A8A_FOOTER_TEXT
    )

    let printA8AHideText = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PRINT_A8A_HIDE_TEXT
    )

    let printA8AShowText = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PRINT_A8A_SHOW_TEXT
    )


    return {
      ehblHouseBill,
      manifestId,
      corpid,
      movementType,
      indicatorMode,
      primaryCcn,
      carrierCode,
      ccn,
      portOfDischargeCode,
      portOfDestCode,
      messageFuncCode,
      consolidationInd,
      totalWeight,
      status,
      closeStatus,
      responseDatetime,
      serviceBureauId,
      upsClientId,
      userRole,
      email,
      mobileNo,
      totalWeightUom,
      shipperName,
      consigneeName,
      entryNo,
      amendmentCode,
      ucn,
      b2bComment,
      destSubLocCode,
      discSubLocCode,
      undgContactName,
      undgContactNo,
      specialInstruction,
      isDangerousGoods,
      hanInstruction,
      volume,
      volumeUom,
      containerizedInd,
      mfInd,
      mfDatetime,
      businessName,
      freightFwdCode,
      uniqueRefNo,
      clMsgInd,
      prevCcn,
      noticeReasonCode,
      houseRefNo,
      usPortOfExit,
      usPortOfExitDesc,
      frnPortOfLoading,
      frnPortOfLoadingDesc,
      remarks,
      printDate,
      accountOff,
      storageDate,
      partyId,
      submittedBy,
      submittedDate,
      templateName,
      modeOfSubmission,
      cargoHeader,
      containerDetails,
      hblId,
      mfId,
      equipmentId,
      equipmentType,
      sealNo,
      tradeParty,
      partyName,
      partyType,
      division,
      locPortCode,
      poBoxNo,
      address,
      city,
      stateCode,
      countryCode,
      postalCode,
      contactName,
      telephoneNum,
      snpType,
      snpUcn,
      snpB2bComment,
      snpIdentifierValue,
      cargoDetails,
      productDetails,
      quantity,
      uom,
      description,
      marksAndNumbers,
      hsCode,
      undgCode,
      weight,
      weightUom,
      statusNotifyParties,
      name,
      snType,
      hblCcn,
      hpNum,
      module,
      printA8A,
      printA8AFooterText,
      printA8AHideText,
      printA8AShowText
    }
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StatusBar status={data.status} />
        </Grid>
        <Grid item xs={12}>
          <CngSection title={translatedTextsObject.ehblHouseBill} >
            <CardContent>
              <Grid container spacing={1}>
                {data.status!="MF" &&
                <CngGridItem xs={12} sm={6} lg={4}>
                  <CngField label={translatedTextsObject.houseRefNo}>
                    {data.houseRefNo}
                  </CngField>
                </CngGridItem>
                }
                <CngGridItem xs={12} sm={6} lg={4}>
                  <CngField label={translatedTextsObject.movementType}>
                    <Typography component='div' variant='inherit'>
                      {getLookupValue('movementType', data.movementType)}
                      <TinyChip label={data.movementType} variant='outlined' />
                    </Typography>
                  </CngField>

                </CngGridItem>

                <CngGridItem xs={12} sm={6} lg={4}>
                  <CngField label={translatedTextsObject.indicatorMode}>
                    <Typography component='div' variant='inherit'>
                      {getLookupValue('modeIndicator', data.indicatorMode)}
                      <TinyChip label={data.indicatorMode} variant='outlined' />
                    </Typography>
                  </CngField>
                </CngGridItem>

                <CngGridItem xs={12} sm={6} lg={4}>
                  <CngField label={translatedTextsObject.carrierCode}>
                    {data.carrierCode}
                  </CngField>
                </CngGridItem>
                <CngGridItem xs={12} sm={6} lg={4}>
                  <CngField label={translatedTextsObject.portOfDestCode}>
                    <Typography component='div' variant='inherit'>
                      {getLookupValue('customsOffice', data.portOfDestCode)}
                      <TinyChip label={data.portOfDestCode} variant='outlined' />
                    </Typography>
                  </CngField>

                </CngGridItem>

                <CngGridItem xs={12} sm={6} lg={4}>
                  <CngField label={translatedTextsObject.destSubLocCode}>
                    <Typography component='div' variant='inherit'>
                      {getLookupValue('sublocation', data.destSubLocCode)}
                      <TinyChip label={data.destSubLocCode} variant='outlined' />
                    </Typography>
                  </CngField>
                </CngGridItem>

                <CngGridItem xs={12} sm={6} lg={4}>
                  <CngField label={translatedTextsObject.ccn}>
                    {data.ccn}
                  </CngField>
                </CngGridItem>
                <CngGridItem xs={12} sm={6} lg={4}>
                  <CngField label={translatedTextsObject.portOfDischargeCode}>
                    <Typography component='div' variant='inherit'>
                      {getLookupValue('customsOffice', data.portOfDischargeCode)}
                      <TinyChip label={data.portOfDischargeCode} variant='outlined' />
                    </Typography>
                  </CngField>
                </CngGridItem>

                <CngGridItem xs={12} sm={6} lg={4}>
                  <CngField label={translatedTextsObject.discSubLocCode}>
                    <Typography component='div' variant='inherit'>
                      {getLookupValue('sublocation', data.discSubLocCode)}
                      <TinyChip label={data.discSubLocCode} variant='outlined' />
                    </Typography>
                  </CngField>
                </CngGridItem>

                <CngGridItem xs={12} sm={6} lg={4}>
                  <CngField label={translatedTextsObject.primaryCcn}>
                    {data.primaryCcn}
                  </CngField>
                </CngGridItem>

                <CngGridItem xs={12} sm={6} lg={4}>
                  <CngField label={translatedTextsObject.businessName}>
                    {data.businessName}
                  </CngField>
                </CngGridItem>

                <CngGridItem xs={12} sm={6} lg={4}>
                  <CngField label={translatedTextsObject.freightFwdCode}>
                    {data.freightFwdCode}
                  </CngField>
                </CngGridItem>

                <CngGridItem xs={12} sm={6} lg={4}>
                  <CngField label={translatedTextsObject.prevCcn}>
                    {data.prevCcn}
                  </CngField>
                </CngGridItem>

                <CngGridItem xs={12} sm={6} lg={8}>
                  <CngField label={translatedTextsObject.consolidationInd}>
                    {data.consolidationInd ? 'Yes' : 'No'}
                  </CngField>
                </CngGridItem>

                <CngGridItem xs={12} sm={12} lg={12}>
                  <CngField label={translatedTextsObject.remarks}>
                    {data.remarks}
                  </CngField>
                </CngGridItem>

                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <CngGridItem xs={12} sm={6} lg={4}>
                      <CngField label={translatedTextsObject.usPortOfExit}>
                        {data.usPortOfExit}
                      </CngField>
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} lg={4}>
                      <CngField label={translatedTextsObject.frnPortOfLoading}>
                        {data.frnPortOfLoading}
                      </CngField>
                    </CngGridItem>

                    <CngGridItem xs={12} sm={6} lg={4}>
                      <CngField label={translatedTextsObject.accountOff}>
                        {data.accountOff}
                      </CngField>
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} lg={4}>
                      <CngField label={translatedTextsObject.printDate}>
                        {data.printDate}
                      </CngField>
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} lg={4}>
                      <CngField label={translatedTextsObject.storageDate}>
                        {data.storageDate}
                      </CngField>
                    </CngGridItem>
                  </Grid>

                </Grid>

                <Grid item xs={12}>
                  <CngSection title={translatedTextsObject.tradeParty}>

                    <TradePartyTable
                      data={data.tradeParty}
                      onViewTradeParty={(tradeParty) => {
                        setViewTradePartyDialog({ open: true, tradeParty: tradeParty })
                      }}
                    />
                    <CngGridItem xs={12} sm={12}>

                      <CngField label={translatedTextsObject.b2bComment}>
                        {data.b2bComment}
                      </CngField>
                    </CngGridItem>
                  </CngSection>
                </Grid>

                <Grid item xs={12}>

                  <CngSection title={translatedTextsObject.cargoHeader}>
                    <Grid container spacing={1}>
                      <CngGridItem xs={12} sm={12}>
                        <CngField label={translatedTextsObject.containerizedInd}>
                          {data.containerizedInd ? 'Yes' : 'No'}
                        </CngField>
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} lg={4}>

                        <CngField label={translatedTextsObject.hanInstruction}>
                          {data.hanInstruction}
                        </CngField>
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} lg={4}>
                        <CngField label={translatedTextsObject.totalWeight}>
                          {data.totalWeight}
                        </CngField>
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} lg={4}>
                        <CngField label={translatedTextsObject.totalWeightUom}>
                          <Typography component='div' variant='inherit'>
                            {getLookupValue('weightUOM', data.totalWeightUom)}
                            <TinyChip label={data.totalWeightUom} variant='outlined' />
                          </Typography>
                        </CngField>
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} lg={4}>

                        <CngField label={translatedTextsObject.isDangerousGoods}>
                          {data.isDangerousGoods ? 'Yes' : 'No'}
                        </CngField>
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} lg={4} >
                        <CngField label={translatedTextsObject.volume}>
                          {data.volume}
                        </CngField>
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} lg={4}>
                        <CngField label={translatedTextsObject.volumeUom}>
                          <Typography component='div' variant='inherit'>
                            {getLookupValue('volumeUOM', data.volumeUom)}
                            <TinyChip label={data.volumeUom} variant='outlined' />
                          </Typography>
                        </CngField>
                      </CngGridItem>

                      {data.isDangerousGoods && <>
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <CngGridItem xs={12} sm={6} lg={4}>

                              <CngField label={translatedTextsObject.undgContactName}>
                                {data.undgContactName}
                              </CngField>
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} lg={4}>

                              <CngField label={translatedTextsObject.undgContactNo}>
                                {data.undgContactNo}
                              </CngField>
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} lg={4}>

                              <CngField label={translatedTextsObject.specialInstruction}>
                                {data.specialInstruction}
                              </CngField>
                            </CngGridItem>
                          </Grid>
                        </Grid>
                      </>}
                      {data.containerizedInd &&
                        <Grid item xs={12}>
                          <CngSection title={translatedTextsObject.containerDetails}>
                            <ContainerDetailsTable
                              data={data.containerDetails}
                              onViewContainerDetails={(containerDetails) => {
                                setViewContainerDetailsDialog({ open: true, containerDetails: containerDetails })
                              }}
                            //onAddContainerDetails={handleAddContainerDetails}
                            //onDeleteContainerDetails={handleDeleteContainerDetails}
                            //onEditContainerDetails={handleEditContainerDetails}
                            //showNotification={showNotification}
                            />
                          </CngSection>
                        </Grid>
                      }

                    </Grid>
                  </CngSection>
                </Grid>

                <Grid item xs={12}>
                  <CngSection title={translatedTextsObject.cargoDetails}>
                    <ProductDetailsTable
                      data={data.productDetails}
                      onViewProductDetails={(productDetails) => {
                        setViewProductDetailsDialog({ open: true, productDetails: productDetails })
                      }}
                    //onAddProductDetails={handleAddProductDetails}
                    //onDeleteProductDetails={handleDeleteProductDetails}
                    //onEditProductDetails={handleEditProductDetails}
                    //showNotification={showNotification}
                    />
                  </CngSection>
                </Grid>

                { data.status!="MF" &&
                <Grid item xs={12}>
                  <CngSection title={translatedTextsObject.statusNotifyParties}>
                    {data.statusNotifyParties.length > 0 ? (
                      <Grid container spacing={3}>
                        {data.statusNotifyParties.map((notification, index) => (
                          <React.Fragment key={notification.id}>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={4}>
                                  <CngField label={translatedTextsObject.snType}>
                                    <Typography component='div' variant='inherit'>
                                      {getLookupValue('snType', notification.snType)}
                                      <TinyChip label={notification.snType} variant='outlined' />
                                    </Typography>
                                  </CngField>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                  <CngField label={translatedTextsObject.email}>
                                    {notification.email}
                                  </CngField>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                  <CngField label={translatedTextsObject.hpNum}>
                                    {notification.hpNum}
                                  </CngField>
                                </Grid>
                              </Grid>
                            </Grid>
                            {data.statusNotifyParties.length !== index + 1 && (
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                            )}
                          </React.Fragment>
                        ))}
                      </Grid>
                    ) : (
                      <Typography variant='inherit' color='textSecondary'>
                        No records to display
                      </Typography>
                    )}
                  </CngSection>
                </Grid>
                }


                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="frnPortOfLoadingDesc"
                    label={translatedTextsObject.frnPortOfLoadingDesc}
                    disabled={disabled}
                  />
                </CngGridItem>

                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="manifestId"
                    label={translatedTextsObject.manifestId}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="corpid"
                    label={translatedTextsObject.corpid}
                    disabled={disabled}
                  />
                </CngGridItem>

                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="messageFuncCode"
                    label={translatedTextsObject.messageFuncCode}
                    disabled={disabled}
                  />
                </CngGridItem>


                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="status"
                    label={translatedTextsObject.status}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="closeStatus"
                    label={translatedTextsObject.closeStatus}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngDateField
                    name="responseDatetime"
                    label={translatedTextsObject.responseDatetime}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="serviceBureauId"
                    label={translatedTextsObject.serviceBureauId}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="upsClientId"
                    label={translatedTextsObject.upsClientId}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="userRole"
                    label={translatedTextsObject.userRole}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="email"
                    label={translatedTextsObject.email}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="mobileNo"
                    label={translatedTextsObject.mobileNo}
                    disabled={disabled}
                  />
                </CngGridItem>

                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="shipperName"
                    label={translatedTextsObject.shipperName}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="consigneeName"
                    label={translatedTextsObject.consigneeName}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="entryNo"
                    label={translatedTextsObject.entryNo}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="amendmentCode"
                    label={translatedTextsObject.amendmentCode}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="ucn"
                    label={translatedTextsObject.ucn}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="b2bComment"
                    label={translatedTextsObject.b2bComment}
                    disabled={disabled}
                  />
                </CngGridItem>

                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="mfInd"
                    label={translatedTextsObject.mfInd}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngDateField
                    name="mfDatetime"
                    label={translatedTextsObject.mfDatetime}
                    disabled={disabled}
                  />
                </CngGridItem>


                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="uniqueRefNo"
                    label={translatedTextsObject.uniqueRefNo}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="clMsgInd"
                    label={translatedTextsObject.clMsgInd}
                    disabled={disabled}
                  />
                </CngGridItem>

                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="noticeReasonCode"
                    label={translatedTextsObject.noticeReasonCode}
                    disabled={disabled}
                  />
                </CngGridItem>


                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="usPortOfExitDesc"
                    label={translatedTextsObject.usPortOfExitDesc}
                    disabled={disabled}
                  />
                </CngGridItem>

                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="partyId"
                    label={translatedTextsObject.partyId}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="submittedBy"
                    label={translatedTextsObject.submittedBy}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngDateField
                    name="submittedDate"
                    label={translatedTextsObject.submittedDate}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="templateName"
                    label={translatedTextsObject.templateName}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="modeOfSubmission"
                    label={translatedTextsObject.modeOfSubmission}
                    disabled={disabled}
                  />
                </CngGridItem>
              </Grid>
            </CardContent>
          </CngSection>
        </Grid>
        <Grid item xs={12}>
                <ActivityLogs houseBillId={data.id} />
            </Grid>        
      </Grid>
      <TradePartyDialog
        isView
        open={viewTradePartyDialog.open}
        onClose={() => {
          setViewTradePartyDialog({ open: false, tradeParty: null })
        }}
        tradeParty={viewTradePartyDialog.tradeParty}
        translatedTextsObject={translatedTextsObject}
        getCountryStateLabel={getCountryStateLabel}
        getLookupValue={getLookupValue}
      />
      <ContainerDetailsDialog
        isView
        open={viewContainerDetailsDialog.open}
        onClose={() =>
          setViewContainerDetailsDialog({ open: false, containerDetails: null })
        }
        containerDetails={viewContainerDetailsDialog.containerDetails}
        translatedTextsObject={translatedTextsObject}
        getCountryStateLabel={getCountryStateLabel}
        getLookupValue={getLookupValue}
      />
      <ProductDetailsDialog
        isView
        open={viewProductDetailsDialog.open}
        onClose={() =>
          setViewProductDetailsDialog({ open: false, productDetails: null })
        }
        productDetails={viewProductDetailsDialog.productDetails}
        translatedTextsObject={translatedTextsObject}
        getCountryStateLabel={getCountryStateLabel}
        getLookupValue={getLookupValue}
      />
    </>
  )
}

export default HouseBillViewContent
