import React, { useEffect, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import ResponseLog from './ResponseLog'
import EhblHouseBillApiUrls from 'src/apiUrls/EhblHouseBillApiUrls'

const { button: { CngButton } } = components
const { filter: { EQUAL } } = constants

function ViewHouseBillLogsHistory(props) {
  const { history, manifestId, onSubmitManifest, houseBillData } = props
  const { fetchRecords } = useServices()

  return (
    <>
      <ResponseLog manifestId={""+houseBillData.current?.manifestId+""} ccn={houseBillData.current?.ccn} />
    </>
  )
}

export default ViewHouseBillLogsHistory
