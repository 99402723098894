import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
import EhblHouseBillKeys from 'src/constants/locale/key/EhblHouseBill'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  const errMsgAlphaNumeric = translate(
    Namespace.EHBL_HOUSE_BILL,
    EhblHouseBillKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC
  )
  const errMsgAlphaNumericWithSpecialChar = translate(
    Namespace.EHBL_HOUSE_BILL,
    EhblHouseBillKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_WITH_SPECIAL_CHAR
  )

  const errMsgAlphaNumericWithSpecialCharNoSpace = translate(
    Namespace.EHBL_HOUSE_BILL,
    EhblHouseBillKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_WITH_SPECIAL_CHAR_NO_SPACE
  )

  const errMsgAlphaNumericWithHypen = translate(
    Namespace.EHBL_HOUSE_BILL,
    EhblHouseBillKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_WITH_HYPEN
  )

  const errMsgNumeric = translate(
    Namespace.EHBL_HOUSE_BILL,
    EhblHouseBillKeys.ValidationMessage.ERROR_MSG_NUMERIC
  )

  const errMsgMinLength = translate(
    Namespace.EHBL_HOUSE_BILL,
    EhblHouseBillKeys.ValidationMessage.ERROR_MSG_MIN_LENGTH
  )
  const errMsgMaxLength = translate(
    Namespace.EHBL_HOUSE_BILL,
    EhblHouseBillKeys.ValidationMessage.ERROR_MSG_MAX_LENGTH
  )

  const regexNumeric = '^[0-9]*$'
  const regexAlphaNumeric = '^[a-zA-Z0-9]*$'
  const regexAlphaNumericWithHypen = '^[a-zA-Z0-9-]*$'
  const regexAlphaNumericWithSpecialChar =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'

  const regexAlphaNumericWithSpecialCharNoSpace =
    '^[a-zA-Z0-9,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\]+$'

  return Yup.object({
    hblId: Yup.string().nullable(),
    mfId: Yup.string().nullable(),
    quantity: Yup.string().required(requiredMessage).matches(regexNumeric, errMsgNumeric).max(8, errMsgMaxLength + " 8"),
    uom: Yup.string().required(requiredMessage).nullable(),
    description: Yup.string().required(requiredMessage).matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(256, errMsgMaxLength + " 256"),
    marksAndNumbers: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(35, errMsgMaxLength + " 35"),
    hsCode: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(10, errMsgMaxLength + " 10"),
    undgCode: Yup.string().nullable(),
    weight: Yup.string().nullable().matches(regexNumeric, errMsgNumeric).max(13, errMsgMaxLength + " 13"),
    weightUom: Yup.string().nullable(),
  })
}

export default makeValidationSchema
