import React, { useEffect } from 'react'
import { components, constants, useTranslation } from 'cng-web-lib'
import FormProperties from './ProductDetailsFormProperties'
import { Box, Grid } from '@material-ui/core'
import CngSection from 'src/components/cngcomponents/CngSection'
import CngField from 'src/components/cngcomponents/CngField'
import Namespace from 'src/constants/locale/Namespace'
import EhblHouseBillKeys from 'src/constants/locale/key/EhblHouseBill'

const {
    button: { CngButton, CngPrimaryButton },
    form: { CngForm },
    CngDialog,
    CngGridItem
} = components

const { FormState } = constants

function ProductDetailsDialog(props) {
    const {
        getLookupValue,
        getCountryStateLabel,
        onAddProductDetails,
        onEditProductDetails,
        isView,
        onClose,
        open,
        showNotification,
        productDetails
    } = props

    const { initialValues, makeValidationSchema } = FormProperties.formikProps

    const { translate } = useTranslation(Namespace.EHBL_HOUSE_BILL)
    const translatedTextsObject = makeTranslatedTextsObject()

    function makeTranslatedTextsObject() {
        let productDetails = translate(
          Namespace.EHBL_HOUSE_BILL,
          EhblHouseBillKeys.ProductDetails.TITLE
        )
        let hblId = translate(
          Namespace.EHBL_HOUSE_BILL,
          EhblHouseBillKeys.ProductDetails.HBL_ID
        )
        let mfId = translate(
          Namespace.EHBL_HOUSE_BILL,
          EhblHouseBillKeys.ProductDetails.MF_ID
        )
        let quantity = translate(
          Namespace.EHBL_HOUSE_BILL,
          EhblHouseBillKeys.ProductDetails.QUANTITY
        )
        let uom = translate(
          Namespace.EHBL_HOUSE_BILL,
          EhblHouseBillKeys.ProductDetails.UOM
        )
        let description = translate(
          Namespace.EHBL_HOUSE_BILL,
          EhblHouseBillKeys.ProductDetails.DESCRIPTION
        )
        let marksAndNumbers = translate(
          Namespace.EHBL_HOUSE_BILL,
          EhblHouseBillKeys.ProductDetails.MARKS_AND_NUMBERS
        )
        let hsCode = translate(
          Namespace.EHBL_HOUSE_BILL,
          EhblHouseBillKeys.ProductDetails.HS_CODE
        )
        let undgCode = translate(
          Namespace.EHBL_HOUSE_BILL,
          EhblHouseBillKeys.ProductDetails.UNDG_CODE
        )
        let weight = translate(
          Namespace.EHBL_HOUSE_BILL,
          EhblHouseBillKeys.ProductDetails.WEIGHT
        )
        let weightUom = translate(
          Namespace.EHBL_HOUSE_BILL,
          EhblHouseBillKeys.ProductDetails.WEIGHT_UOM
        )
    
        let undgCodeTitle = translate(
          Namespace.EHBL_HOUSE_BILL,
          EhblHouseBillKeys.ProductDetails.UNDG_CODE_TITLE
        )
    
        let updateButton = translate(
            Namespace.EHBL_HOUSE_BILL,
            EhblHouseBillKeys.UPDATE_BUTTON
          )
      
          let saveButton = translate(
            Namespace.EHBL_HOUSE_BILL,
            EhblHouseBillKeys.SAVE_BUTTON
          )

        return {
          productDetails,
          hblId,
          mfId,
          quantity,
          uom,
          description,
          marksAndNumbers,
          hsCode,
          undgCode,
          weight,
          weightUom,
          undgCodeTitle,
          updateButton,
          saveButton
        }
      }

    if (!open) {
        return null
    }

    
    let undgCodeList = []
    if(productDetails && productDetails.undgCode!=null && productDetails.undgCode!=undefined){
        undgCodeList = productDetails.undgCode.split("|")
    }

    return (
        <CngDialog
            dialogContent={
                isView && productDetails ? (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <CngSection title={translatedTextsObject.productDetails}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <CngField label={translatedTextsObject.description}>
                                            {productDetails.description}
                                        </CngField>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <CngField label={translatedTextsObject.quantity}>
                                            {productDetails.quantity}
                                        </CngField>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <CngField label={translatedTextsObject.uom}>
                                            {productDetails.uom}
                                        </CngField>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <CngField label={translatedTextsObject.marksAndNumbers}>
                                            {productDetails.marksAndNumbers}
                                        </CngField>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <CngField label={translatedTextsObject.weight}>
                                            {productDetails.weight}
                                        </CngField>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <CngField label={translatedTextsObject.weightUom}>
                                            {productDetails.weightUom}
                                        </CngField>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <CngField label={translatedTextsObject.hsCode}>
                                            {productDetails.hsCode}
                                        </CngField>
                                    </Grid>
                                    <CngGridItem xs={12}>
                                        <CngSection title={translatedTextsObject.undgCodeTitle}>
                                        <Grid item xs={12}>
                                                  <Grid container spacing={2}>
                                        {undgCodeList.length>0 && undgCodeList.map((undgCode, index) => (
                                               <>
                                               
                                                    <Grid item xs={12} sm={6} md={4}>
                                                      <CngField label={translatedTextsObject.undgCode}>
                                                        {undgCode}
                                                      </CngField>
                                                    </Grid>
                                                   
                                               </>
                                            ))}
                                             </Grid>
                                                    </Grid>
                                        </CngSection>
                                    </CngGridItem>
                                </Grid>
                            </CngSection>
                        </Grid>
                    </Grid>
                ) : (
                    <CngForm
                        fieldLevel='form'
                        formikProps={{
                            initialValues: productDetails || initialValues,
                            makeValidationSchema: makeValidationSchema,
                            onSubmit: (data) => {
                                if (productDetails) {
                                    onEditProductDetails(data)
                                } else {
                                    onAddProductDetails(data)
                                }

                                onClose()
                            }
                        }}
                        formState={FormState.COMPLETED}
                        renderBodySection={(labelMap, shouldHideMap) => (
                            <Box padding={1}>
                                <FormProperties.Fields
                                    showNotification={showNotification}
                                    shouldHideMap={shouldHideMap}
                                />
                            </Box>
                        )}
                        innerForm={true}
                        renderButtonSection={() => (
                            <Box display='flex' justifyContent='flex-end'>
                                <CngPrimaryButton type='submit'>
                                    {productDetails
                                        ? translatedTextsObject.updateButton
                                        : translatedTextsObject.saveButton}
                                </CngPrimaryButton>
                            </Box>
                        )}
                    />
                )
            }
            dialogAction={
                isView ? <CngButton onClick={onClose}>OK</CngButton> : null
            }
            dialogTitle={translatedTextsObject.productDetails}
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            fullWidth
            maxWidth='lg'
            onClose={onClose}
            open={open}
            shouldShowCloseButton
        />
    )
}

export default ProductDetailsDialog
