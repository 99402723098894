import EhblHouseBillApiUrls from 'src/apiUrls/EhblHouseBillApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React,{useRef,useState} from 'react'
import { components } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import pathMap from '../../../paths/pathMap'

const {
  button: { CngButton },
  form: { CngEditForm }
} = components

function EditPage({ history,showNotification,houseBillData }) {
  const { id } = useParams()
  const onSuccessCallback = useRef(() => {})
  const [lookups, setLookups] = useState(null)
  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }
  function getCountryStateLabel(country, state) {
    if (!lookups || !country || !state) return state

    // Check if country code exists in the lookup
    if (Object.keys(lookups.countries).includes(country)) {
      const result = lookups.countryStates[country].find(
        (countryState) => countryState.intlcode === state
      )

      return result ? result.descriptionEn : state
    } else {
      return state
    }
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngEditForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
              getLookupValue={getLookupValue}
              getCountryStateLabel={getCountryStateLabel}
            />
          )}
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{
            url: EhblHouseBillApiUrls.GET,
            onPreSuccess: (serverData) => {
              houseBillData.current = serverData
            }
          }}
          update={{
            url: EhblHouseBillApiUrls.PUT,
            onPostSubmitSuccess: (e) => {
              houseBillData.current = e
              onSuccessCallback.current()
            }
          }}
          id={id}
          renderButtonSection={(_, loading) => (
            <ButtonSection
                history={history}
                loading={loading}
            />
        )}
        />
      </Grid>
    </Grid>
  )
}

export default EditPage

function ButtonSection(props) {
  const { history, loading } = props
  const [confirmDialog, setConfirmDialog] = useState(false)

  return (
      <>
          <Grid container justify='space-between' spacing={3}>
              <Grid item xs='auto'>
                  <CngButton color='secondary' disabled={loading} onClick={() => setConfirmDialog(true)} size='medium'>Discard</CngButton>
              </Grid>
              <Grid item xs='auto'>
                  <Grid container spacing={2}>
                      <Grid item xs='auto'>
                          <CngButton color='primary' disabled={loading} type='submit' size='medium'>Update</CngButton>
                      </Grid>
                  </Grid>
              </Grid>
          </Grid>
          <AlertDialog
              cancelLabel='Continue editing'
              confirmLabel='Yes, discard'
              open={confirmDialog}
              onClose={() => setConfirmDialog(false)}
              onCancel={() => setConfirmDialog(false)}
              onConfirm={() => history.push(pathMap.EHBL_HOUSE_BILL_LIST_VIEW)}
              title='Discard'
          >
              All progress in this session will be lost and cant't be restored. Are
              you sure about this?
          </AlertDialog>
      </>
  )
}
