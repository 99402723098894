import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {constants} from 'cng-web-lib'
import EhblHouseBillKeys from 'src/constants/locale/key/EhblHouseBill'


const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  const errMsgAlphaNumericWithSpecialChar = translate(
    Namespace.EHBL_HOUSE_BILL,
    EhblHouseBillKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_WITH_SPECIAL_CHAR
  )

  const errMsgMaxLength = translate(
    Namespace.EHBL_HOUSE_BILL,
    EhblHouseBillKeys.ValidationMessage.ERROR_MSG_MAX_LENGTH
  )

  const errMsgAlphaNumeric = translate(
    Namespace.EHBL_HOUSE_BILL,
    EhblHouseBillKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC
  )

  const regexAlphaNumeric = '^[a-zA-Z0-9 ]*$'
  const regexAlphaNumericWithSpecialChar = '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'

  return Yup.object({
    snType: Yup.string().required(requiredMessage).nullable(),
    email: Yup.string().trim().required(requiredMessage).matches(regexAlphaNumericWithSpecialChar,errMsgAlphaNumericWithSpecialChar).max(254, errMsgMaxLength+" 254").nullable(),
    hpNum: Yup.string().trim().max(25,  errMsgMaxLength+" 25").matches(regexAlphaNumeric, errMsgAlphaNumeric).nullable(),
  }) 
}

export default makeValidationSchema
