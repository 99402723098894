import { Container, Grid, Paper } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import HouseBillEditForm from './HouseBillEditPage'
import Namespace from 'src/constants/locale/Namespace'
import { components, useTranslation } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import EhblHouseBillKeys from 'src/constants/locale/key/EhblHouseBill'
import StatusNotificationListPage from './StatusNotificationListPage'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'
import ViewHouseBillLogsHistory from './ViewHouseBillLogsHistory'

const { CngTabs } = components

function EditPage({ history, loading, location, onSetLoading, showNotification }) {
  const { id } = useParams()
  const { state } = location
  const { translate } = useTranslation([Namespace.EHBL_HOUSE_BILL])
  const translatedTextsObject = makeTranslatedTextsObject()
  const houseBillData = useRef(state?.houseBillData || null)
  
  function makeTranslatedTextsObject() {
    let editHousebill = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.EDIT_HOUSE_BILL
    )
    let setStatusNotification= translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SET_STATUS_NOTIFICATION
    )
    let housebillResponse= translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.RESPONSE_TITLE
    )

    return {
      editHousebill,
      setStatusNotification,
      housebillResponse
    }
  }


  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.editHousebill,
              tabContent: (
                <HouseBillEditForm
                  showNotification={showNotification}
                  id={id}
                  houseBillData={houseBillData}
                  history={history}
                />
              )
            },
            {
              tabName: translatedTextsObject.setStatusNotification,
              tabContent: (
                <StatusNotificationListPage
                  history={history}
                  showNotification={showNotification}
                  hblId={id}
                  //manifestData={manifestData}
                  onSetLoading={onSetLoading}
                  //onSubmitManifest={handleSubmitManifest}
                  //onChangeStep={(step) => setStep(step)}
                  //step={step}
                />
              )
            },
            {
              tabName: translatedTextsObject.housebillResponse,
              tabContent: (
                <ViewHouseBillLogsHistory
                  houseBillData={houseBillData}
                  history={history}
                  //onChangeStep={(step) => setStep(step)}
                  //onSubmitManifest={handleSubmitManifest}
                  //step={step}
                  //tripNo={manifestData.current?.tripNum}
                />
              )
            }
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default withFormState(EditPage)
