import makeValidationSchema from './ProductDetailsMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React,{useEffect,useState} from 'react'
import Namespace from 'src/constants/locale/Namespace'
import EhblHouseBillKeys from 'src/constants/locale/key/EhblHouseBill'
import { Card, CardContent, Grid,Typography,Divider,InputAdornment,IconButton,Box } from '@material-ui/core'
import {
  components,
  constants
} from 'cng-web-lib'
import { NaDangerousGoodsAutocompleteField, NaHSCodeAutocompleteField, NaProductMasterAutoCompleteField,NaEhblUOMAutocompleteField,NaProductMasterServerAutoCompleteField } from 'src/components/na'	
import UNDGSection from './UNDGSection'
import { useFormContext, useWatch } from "react-hook-form";
import CngSection from '../../../components/cngcomponents/CngSection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FileForUserGetPartyId, FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import ProductMasterApiUrls from 'src/apiUrls/ProductMasterApiUrls'
import TemplateSelectDialog from '../../../components/aciacehighway/TemplateSelectDialog'


const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngCodeMasterAutocompleteField,
      CngUomAutocompleteField,
      CngCheckboxField
    },
  },
  CngGridItem,
} = components

const {
  filter: { LIKE, EQUAL,IN }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  hblId: null,
  mfId: null,
  quantity: "",
  uom: "",
  description: "",
  marksAndNumbers: "",
  hsCode: "",
  undgCode: "",
  weight: "",
  weightUom: "",
  undgCodeList: [],
  saveFlag:false
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {

  const { translate } = useTranslation(Namespace.EHBL_HOUSE_BILL)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { setValue, reset, watch,getValues,trigger } = useFormContext();
  const saveFlag = watch('saveFlag');
  const productId = watch('productId');
  const [templateDialog, setTemplateDialog] = useState(false)

  const undgCodeList = watch('undgCodeList');

  useEffect(()=>{
    let undgCode = getValues('undgCode');

    if(undgCode!=null && undgCode!=undefined && undgCode!=""){
      let undgCodeStringList = undgCode.split("|")
      let undgCodeList = []

      for(let i=0; i<undgCodeStringList.length; i++){
          let undgCodeObject = {}
          undgCodeObject.undgCodeValue = undgCodeStringList[i]
          undgCodeList.push(undgCodeObject)
      }

      setValue('undgCodeList',undgCodeList);
    }
  },[])

  function makeTranslatedTextsObject() {
    let productDetails = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.TITLE
    )
    let hblId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.HBL_ID
    )
    let mfId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.MF_ID
    )
    let quantity = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.QUANTITY
    )
    let uom = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.UOM
    )
    let description = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.DESCRIPTION
    )
    let marksAndNumbers = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.MARKS_AND_NUMBERS
    )
    let hsCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.HS_CODE
    )
    let undgCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.UNDG_CODE
    )
    let weight = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.WEIGHT
    )
    let weightUom = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.WEIGHT_UOM
    )

    let undgCodeTitle = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.UNDG_CODE_TITLE
    )

    let saveFlag = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.SAVE_FLAG
    )

    let productId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.PRODUCT_ID
    )

    return {
      productDetails,
      hblId,
      mfId,
      quantity,
      uom,
      description,
      marksAndNumbers,
      hsCode,
      undgCode,
      weight,
      weightUom,
      undgCodeTitle,
      saveFlag,
      productId
    }
  }

  const columns = [
    {
      field: 'productidn',
      title: translatedTextsObject.partyIdentification
    },
    {
      field: 'productDesc1',
      title: translatedTextsObject.description,
    },
    {
      title:  'marksAndNumbers',
      title: translatedTextsObject.marksAndNumbers,
    }
  ]

  const sortOptions = [
    {
      label: translatedTextsObject.partyIdentification,
      value: 'productidn'
    },
    {
      label: translatedTextsObject.description,
      value: 'productDesc1'
    },
  ]

  function handleApplyTemplate(template) {
    if (template) {
      const {
        productidn,
        productDesc1,
        productDesc2,
        productDesc3,
        productDesc4,
        productDesc5,
        productDesc6,
        productDesc7,
        productDesc8,
        productDesc9,
        hsCode,
        hsCodeDesc,
        countryOfOrigin,
        marksAndNumbers,
        undgs
      } = template

      setValue('description', productDesc1, { shouldDirty: true })
      setValue('hsCode', hsCode, { shouldDirty: true })
      setValue('marksAndNumbers', marksAndNumbers, { shouldDirty: true })

      if(undgs!=null && undgs!=undefined && undgs!=""){
        let list = []
        for (var i = 0, l = undgs.length; i < l; i++) {
          var obj = undgs[i];
          list.push(obj.code)
        }

        setValue('undgCode', list.join("|"), { shouldDirty: true })

        let undgCodeList = []
        for(let i=0; i<list.length; i++){
          let undgCodeObject = {}
          undgCodeObject.undgCodeValue = list[i]
          undgCodeList.push(undgCodeObject)
        }
        setValue('undgCodeList',undgCodeList, { shouldDirty: true })
      }
      
      trigger()
    } else {
      reset()
    }
  }

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.productDetails} />
      <CardContent>
        <Grid container spacing={1}>
        <CngGridItem xs={12} sm={12}>
        <CngGridItem xs={12} lg={8}>
              <Grid container spacing={1}>
                <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.saveFlag}>
                  <CngCheckboxField
                    label={
                      <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                        {translatedTextsObject.saveFlag}
                      </Typography>
                    }
                    name='saveFlag'
                    disabled={disabled}
                    onChange={(e) => {
                      setValue('saveFlag', e.target.checked)

                      if (!e.target.checked) {
                        setValue("productId", "")
                      }
                    }}
                    size='small'
                    style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm shouldHide={shouldHideMap?.productId}>
                  <CngTextField
                    required
                    name="productId" 
                    inputProps={{
                      style: { textTransform: 'uppercase' },
                      maxLength: 35
                    }}
                    placeholder={translatedTextsObject.productId}
                    disabled={disabled || !saveFlag}
                    onChange={(e) => {
                      setValue("productId", e.target.value.toUpperCase(), { shouldValidate: true })
                    }}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm='auto'>
                  <Divider orientation='vertical' />
                </CngGridItem>
                <CngGridItem xs={12} sm={4}>
                  <NaProductMasterAutoCompleteField
                    name="partyDropDown"
                    label={translatedTextsObject.partyDropDown}
                    disabled={disabled}
                    onChange={(_, options) => handleApplyTemplate(options.data)}
                    size='small'
                    fullWidth
                    disableClearable
                    textFieldProps={{
                      InputProps: {
                        customEndAdornment: () => (
                          <InputAdornment position='end' style={{ marginTop: -16 }}>
                            <IconButton onClick={(event) => {
                              event.stopPropagation()
                              setTemplateDialog(true)
                            }}>
                              <Box display='flex' alignItems='center' justifyContent='center' width={16} height={16}>
                                <FontAwesomeIcon icon={['fal', 'money-check-edit']} size='xs' />
                              </Box>
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    }}
                    forcePopupIcon={false}
                    lookupProps={{
                      filters: [{
                        field: 'partyId',
                        operator: EQUAL,
                        value: FileForUserGetPartyId()
                      }
                    ]
                    }} 
                  />
                </CngGridItem>
              </Grid>
            </CngGridItem>
        </CngGridItem> 
        <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.description}>
            <CngTextField
              required
              name="description"
              label={translatedTextsObject.description}
              disabled={disabled}
              size='small'
              onChange={(e) => {
                setValue("description", e.target.value.toUpperCase(), { shouldValidate: true })
              }}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.quantity}>
            <CngTextField
              required
              name="quantity"
              label={translatedTextsObject.quantity}
              disabled={disabled}
              size='small'
              onChange={(e) => {
                setValue("quantity", e.target.value.toUpperCase(), { shouldValidate: true })
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.uom}>
            <CngUomAutocompleteField
              required
              name="uom"
              label={translatedTextsObject.uom}
              disabled={disabled} 
              lookupProps= {{
                label: (record) => {
                  return `${record.code};${record.descriptionEn}`
                },
                filters: [{
                  field: 'uomType',
                  operator: 'equal',
                  value: 'HBL'
                }]
              }} 
              size='small'
            />
          </CngGridItem>
          
          <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.marksAndNumbers}>
            <CngTextField
              name="marksAndNumbers"
              label={translatedTextsObject.marksAndNumbers}
              disabled={disabled}
              size='small'
              onChange={(e) => {
                setValue("marksAndNumbers", e.target.value.toUpperCase(), { shouldValidate: true })
              }}
            />
          </CngGridItem>
          {/*<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.undgCode}>
            <NaDangerousGoodsAutocompleteField
              name="undgCode"
              label={translatedTextsObject.undgCode}
              disabled={disabled}
              lookupProps= {{
                filters: [{
                  field: 'indicator',
                  operator: 'equal',
                  value: 'HBL'
                }]
              }} 

            />
            </CngGridItem>*/}
          
          <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.weight}>
            <CngTextField
              name="weight"
              label={translatedTextsObject.weight}
              disabled={disabled}
              size='small'
              onChange={(e) => {
                setValue("weight", e.target.value.toUpperCase(), { shouldValidate: true })
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.weightUom}>
            <CngCodeMasterAutocompleteField
                  name="weightUom"
                  label={translatedTextsObject.weightUom}
                  disabled={disabled}
                  codeType='EHBL_WEIGHT_UOM'
                  size='small'
                />
          </CngGridItem>
          
          <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.hsCode}>
            <CngTextField
              name="hsCode"
              label={translatedTextsObject.hsCode}
              disabled={disabled}
              size='small'
              onChange={(e) => {
                setValue("hsCode", e.target.value.toUpperCase(), { shouldValidate: true })
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12}>
          <CngSection title={translatedTextsObject.undgCodeTitle}>
          <UNDGSection 
            undgCode={undgCodeList}
          />
          </CngSection>
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="hblId"
              label={translatedTextsObject.hblId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="mfId"
              label={translatedTextsObject.mfId}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
        <TemplateSelectDialog
        columns={columns}
        defaultSorts={[{ field: 'productidn', direction: 'ASC' }]}
        fetch={{ url: ProductMasterApiUrls.GET }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        onApplyTemplate={handleApplyTemplate}
        onClose={() => setTemplateDialog(false)}
        open={templateDialog}
        search={{ field: 'productidn', operator: LIKE }}
        sortOptions={sortOptions}
        title='Template'
      />
      </CardContent>
    </Card>
	)
}

const ProductDetailsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default ProductDetailsFormProperties
