import makeValidationSchema from './UNDGMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React, { useEffect } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import EhblHouseBillKeys from 'src/constants/locale/key/EhblHouseBill'
import { Grid } from '@material-ui/core'
import { NaDangerousGoodsAutocompleteField, NaHSCodeAutocompleteField, NaProductMasterAutoCompleteField } from 'src/components/na'	

const {
  form: {
    field: { CngTextField, CngCodeMasterAutocompleteField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  undgCodeValue: ""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap,
  onEdit,
  methods,
  index
}) {
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let undgCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.UNDG_CODE
    )

    return {
      undgCode
    }
  }

  function handleEditUNDG(data){
    onEdit(index,data);
  }
 
	return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={12} >
            <NaDangerousGoodsAutocompleteField
              name="undgCodeValue"
              label={translatedTextsObject.undgCode}
              disabled={disabled}
              lookupProps= {{
                filters: [{
                  field: 'indicator',
                  operator: 'equal',
                  value: 'HBL'
                }]
              }} 
              onChange={methods.handleSubmit(handleEditUNDG)}
            />
          </CngGridItem>
    </Grid>
	)
}

const CargoSummaryFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default CargoSummaryFormProperties
