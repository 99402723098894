import makeValidationSchema from './ProductDetailsMakeValidationSchema'
import React, { useState,useEffect } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import EhblHouseBillKeys from 'src/constants/locale/key/EhblHouseBill'
import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.mode === 'dark' ? '#282C34' : theme.palette.grey[100],
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

const {
  button: { CngButton, CngIconButton },
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL }
} = constants

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  hblId: "",
  mfId: "",
  quantity: "",
  uom: "",
  description: "",
  marksAndNumbers: "",
  hsCode: "",
  undgCode: "",
  weight: "",
  weightUom: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function ProductDetailsTable({
  data,
  onAddProductDetails,
  onDeleteProductDetails,
  onEditProductDetails,
  onViewProductDetails,
  showNotification
}) {
  const { translate } = useTranslation(Namespace.EHBL_HOUSE_BILL)
  const translatedTextsObject = makeTranslatedTextsObject()

  const [searchTerm, setSearchTerm] = useState('')
  const [lookups, setLookups] = useState(null)
  const classes = useStyles()

  const filtered =
    searchTerm !== ''
      ? data.filter((productDetails) =>
      productDetails.description
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
      : data

  function makeTranslatedTextsObject() {
    let productDetails = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.TITLE
    )
    let hblId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.HBL_ID
    )
    let mfId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.MF_ID
    )
    let quantity = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.QUANTITY
    )
    let uom = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.UOM
    )
    let description = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.DESCRIPTION
    )
    let marksAndNumbers = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.MARKS_AND_NUMBERS
    )
    let hsCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.HS_CODE
    )
    let undgCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.UNDG_CODE
    )
    let weight = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.WEIGHT
    )
    let weightUom = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.WEIGHT_UOM
    )

    let updateButton = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UPDATE_BUTTON
    )

    let saveButton = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SAVE_BUTTON
    )

    return {
      productDetails,
      hblId,
      mfId,
      quantity,
      uom,
      description,
      marksAndNumbers,
      hsCode,
      undgCode,
      weight,
      weightUom,
      updateButton,
      saveButton
    }
  }

  return (
    <>
      <Box marginBottom={1}>
        <Grid container spacing={2}>
          <Grid item xs>
            {data.length > 0 && (
              <TextField
                fullWidth
                onChange={(event) => setSearchTerm(event.target.value)}
                placeholder='Search in your entries'
                size='small'
                variant='outlined'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <FontAwesomeIcon icon={['fal', 'search']} />
                    </InputAdornment>
                  )
                }}
              />
            )}
          </Grid>
          {onAddProductDetails && (
            <Grid item xs='auto'>
              <CngButton
                color='secondary'
                onClick={() =>
                  onAddProductDetails()
                }
                size='medium'
                startIcon={<FontAwesomeIcon icon={['fal', 'plus-circle']} />}
              >
                Add Line Items
              </CngButton>
            </Grid>
          )}
        </Grid>
      </Box>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{translatedTextsObject.quantity}</TableCell>
              <TableCell>{translatedTextsObject.uom}</TableCell>
              <TableCell>{translatedTextsObject.description}</TableCell>
              <TableCell>{translatedTextsObject.marksAndNumbers}</TableCell>
              <TableCell>{translatedTextsObject.hsCode}</TableCell>
              <TableCell>{translatedTextsObject.undgCode}</TableCell>
              <TableCell>{translatedTextsObject.weight}</TableCell>
              <TableCell>{translatedTextsObject.weightUom}</TableCell>
              {(onEditProductDetails || onDeleteProductDetails) && (
                <TableCell align='right'>Action</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filtered.length > 0 ? (
              filtered.map((productDetails) => (
                <TableRow
                  key={productDetails._id || productDetails.id}
                  hover
                  onClick={() => {
                    if (onViewProductDetails) {
                      onViewProductDetails(productDetails)
                    }
                  }}
                >
                  <TableCell>{productDetails.quantity}</TableCell>
                  <TableCell>{productDetails.uom}</TableCell>
                  <TableCell>{productDetails.description}</TableCell>
                  <TableCell>{productDetails.marksAndNumbers}</TableCell>
                  <TableCell>{productDetails.hsCode}</TableCell>
                  <TableCell>{productDetails.undgCode}</TableCell>
                  <TableCell>{productDetails.weight}</TableCell>
                  <TableCell>{productDetails.weightUom}</TableCell>
                  {(onEditProductDetails || onDeleteProductDetails) && (
                    <TableCell align='right'>
                      <Grid container justify='flex-end' spacing={1}>
                        {onEditProductDetails && (
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'pen']}
                              onClick={(event) => {
                                event.stopPropagation()
                                onEditProductDetails(productDetails)
                              }}
                              size='small'
                            />
                          </Grid>
                        )}
                        {onDeleteProductDetails && (
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'trash']}
                              onClick={(event) => {
                                event.stopPropagation()
                                onDeleteProductDetails(productDetails)
                              }}
                              size='small'
                            />
                          </Grid>
                        )}
                      </Grid>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  align='center'
                  colSpan={3 + (onEditProductDetails || onDeleteProductDetails ? 1 : 0)}
                >
                  <Typography variant='inherit' color='textSecondary'>
                    No records to display
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ProductDetailsTable
