import makeValidationSchema from './TradePartyMakeValidationSchema'
import React, { useState,useEffect } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import EhblHouseBillKeys from 'src/constants/locale/key/EhblHouseBill'
import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TradePartyDialog from './TradePartyDialog'

import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.mode === 'dark' ? '#282C34' : theme.palette.grey[100],
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

const {
  button: { CngButton, CngIconButton },
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL }
} = constants

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  hblId: "",
  manifestId: "",
  mfId: "",
  corpid: "",
  partyId: "",
  partyName: "",
  partyType: "",
  division: "",
  locPortCode: "",
  poBoxNo: "",
  address: "",
  city: "",
  stateCode: "",
  countryCode: "",
  postalCode: "",
  contactName: "",
  telephoneNum: "",
  snpType: "",
  snpUcn: "",
  snpB2bComment: "",
  snpIdentifierValue: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function TradePartyTable({
  data,
  onAddTradeParty,
  onDeleteTradeParty,
  onEditTradeParty,
  onViewTradeParty,
  showNotification,
  onCloneTradeParty
}) {
  const { translate } = useTranslation(Namespace.EHBL_HOUSE_BILL)
  const translatedTextsObject = makeTranslatedTextsObject()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const [searchTerm, setSearchTerm] = useState('')
  const [lookups, setLookups] = useState(null)
  const classes = useStyles()

  const filtered =
    searchTerm !== ''
      ? data.filter((tradeParty) =>
        tradeParty.partyName
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
      : data

  useEffect(() => {
    Promise.all([
      // Cargo loaded in
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_PARTY_TYPE' }],
        undefined,
        'code'
      
      )]).then(
        ([
          partyType,
        ]) => {
          setLookups({
            partyType
          })
        })
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }


  function makeTranslatedTextsObject() {
    let tradeParty = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.TITLE
    )
    let hblId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.HBL_ID
    )
    let manifestId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.MANIFEST_ID
    )
    let mfId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.MF_ID
    )
    let corpid = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.CORPID
    )
    let partyId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.PARTY_ID
    )
    let partyName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.PARTY_NAME
    )
    let partyType = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.PARTY_TYPE
    )
    let division = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.DIVISION
    )
    let locPortCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.LOC_PORT_CODE
    )
    let poBoxNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.PO_BOX_NO
    )
    let address = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.ADDRESS
    )
    let city = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.CITY
    )
    let stateCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.STATE_CODE
    )
    let countryCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.COUNTRY_CODE
    )
    let postalCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.POSTAL_CODE
    )
    let contactName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.CONTACT_NAME
    )
    let telephoneNum = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.TELEPHONE_NUM
    )
    let snpType = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.SNP_TYPE
    )
    let snpUcn = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.SNP_UCN
    )
    let snpB2bComment = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.SNP_B2B_COMMENT
    )
    let snpIdentifierValue = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.SNP_IDENTIFIER_VALUE
    )

    let updateButton = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UPDATE_BUTTON
    )

    let saveButton = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SAVE_BUTTON
    )

    let snpAccountSecurityNumber = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.SNP_ACCOUNT_SECURITY_NUMBER
    )

    let snpFreightForwarderCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.SNP_FREIGHT_FORWARDER_CODE
    )

    let snpCbsaCarrierCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.SNP_CBSA_CARRIER_CODE
    )

    let snpWarehouse = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.SNP_WAREHOUSE
    )

    let snpBusinessNumber = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.SNP_BUSINESS_NUMBER
    )

    return {
      tradeParty,
      hblId,
      manifestId,
      mfId,
      corpid,
      partyId,
      partyName,
      partyType,
      division,
      locPortCode,
      poBoxNo,
      address,
      city,
      stateCode,
      countryCode,
      postalCode,
      contactName,
      telephoneNum,
      snpType,
      snpUcn,
      snpB2bComment,
      snpIdentifierValue,
      updateButton,
      saveButton,
      snpIdentifierValue,
      snpAccountSecurityNumber,
      snpFreightForwarderCode,
      snpCbsaCarrierCode,
      snpWarehouse,
      snpBusinessNumber
    }
  }

  return (
    <>
      <Box marginBottom={1}>
        <Grid container spacing={2}>
          <Grid item xs>
            {data.length > 0 && (
              <TextField
                fullWidth
                onChange={(event) => setSearchTerm(event.target.value)}
                placeholder='Search in your entries'
                size='small'
                variant='outlined'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <FontAwesomeIcon icon={['fal', 'search']} />
                    </InputAdornment>
                  )
                }}
              />
            )}
          </Grid>
          {onAddTradeParty && (
            <Grid item xs='auto'>
              <CngButton
                color='secondary'
                onClick={onAddTradeParty}
                size='medium'
                startIcon={<FontAwesomeIcon icon={['fal', 'plus-circle']} />}
              >
                Add Commercial Party
              </CngButton>
            </Grid>
          )}
        </Grid>
      </Box>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{translatedTextsObject.partyId}</TableCell>
              <TableCell>{translatedTextsObject.partyName}</TableCell>
              <TableCell>{translatedTextsObject.partyType}</TableCell>
              <TableCell>{translatedTextsObject.address}</TableCell>
              {(onEditTradeParty || onDeleteTradeParty) && (
                <TableCell align='right'>Action</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filtered.length > 0 ? (
              filtered.map((tradeParty) => (
                <TableRow
                  key={tradeParty._id || tradeParty.id}
                  hover
                  onClick={() => {
                    if (onViewTradeParty) {
                      onViewTradeParty(tradeParty)
                    }
                  }}
                >
                  <TableCell>{tradeParty.partyId}</TableCell>
                  <TableCell>{tradeParty.partyName}</TableCell>
                  <TableCell>{getLookupValue('partyType', tradeParty.partyType)}</TableCell>
                  <TableCell>{tradeParty.address}</TableCell>
                  {(onEditTradeParty || onDeleteTradeParty) && (
                    <TableCell align='right'>
                      <Grid container justify='flex-end' spacing={1}>
                        {onEditTradeParty && (
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'pen']}
                              onClick={(event) => {
                                event.stopPropagation()
                                console.log(tradeParty)
                                onEditTradeParty(tradeParty)
                              }}
                              size='small'
                            />
                          </Grid>
                        )}
                        <Grid item xs='auto'>
                              <CngIconButton
                                icon={['fal', 'copy']}
                                onClick={() => onCloneTradeParty({ ...tradeParty, id: undefined })}
                                size='small'
                                type='outlined'
                              />
                        </Grid>
                        {onDeleteTradeParty && (
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'trash']}
                              onClick={(event) => {
                                event.stopPropagation()
                                onDeleteTradeParty(tradeParty)
                              }}
                              size='small'
                            />
                          </Grid>
                        )}
                      </Grid>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  align='center'
                  colSpan={3 + (onEditTradeParty || onDeleteTradeParty ? 1 : 0)}
                >
                  <Typography variant='inherit' color='textSecondary'>
                    No records to display
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )

  /*return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tradeParty} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.hblId}>
            <CngTextField
              name="hblId"
              label={translatedTextsObject.hblId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.manifestId}>
            <CngTextField
              name="manifestId"
              label={translatedTextsObject.manifestId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.mfId}>
            <CngTextField
              name="mfId"
              label={translatedTextsObject.mfId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.corpid}>
            <CngTextField
              name="corpid"
              label={translatedTextsObject.corpid}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyId}>
            <CngTextField
              name="partyId"
              label={translatedTextsObject.partyId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyName}>
            <CngTextField
              name="partyName"
              label={translatedTextsObject.partyName}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyType}>
            <CngTextField
              name="partyType"
              label={translatedTextsObject.partyType}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.division}>
            <CngTextField
              name="division"
              label={translatedTextsObject.division}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.locPortCode}>
            <CngTextField
              name="locPortCode"
              label={translatedTextsObject.locPortCode}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.poBoxNo}>
            <CngTextField
              name="poBoxNo"
              label={translatedTextsObject.poBoxNo}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.address}>
            <CngTextField
              name="address"
              label={translatedTextsObject.address}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.city}>
            <CngTextField
              name="city"
              label={translatedTextsObject.city}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.stateCode}>
            <CngTextField
              name="stateCode"
              label={translatedTextsObject.stateCode}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.countryCode}>
            <CngTextField
              name="countryCode"
              label={translatedTextsObject.countryCode}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.postalCode}>
            <CngTextField
              name="postalCode"
              label={translatedTextsObject.postalCode}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.contactName}>
            <CngTextField
              name="contactName"
              label={translatedTextsObject.contactName}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.telephoneNum}>
            <CngTextField
              name="telephoneNum"
              label={translatedTextsObject.telephoneNum}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.snpType}>
            <CngTextField
              name="snpType"
              label={translatedTextsObject.snpType}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.snpUcn}>
            <CngTextField
              name="snpUcn"
              label={translatedTextsObject.snpUcn}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.snpB2bComment}>
            <CngTextField
              name="snpB2bComment"
              label={translatedTextsObject.snpB2bComment}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.snpIdentifierValue}>
            <CngTextField
              name="snpIdentifierValue"
              label={translatedTextsObject.snpIdentifierValue}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
  )*/
}


export default TradePartyTable
