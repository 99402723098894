import React from 'react'
import HouseBillAddForm from './HouseBillAddPage'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'
import { Card, CardContent } from '@material-ui/core'

function AddPage({ history, showNotification }) {
  return ( 
  <Card>
    <CardContent>
      <HouseBillAddForm history={history} showNotification={showNotification} />
    </CardContent>
  </Card>
  )
}

export default withFormState(AddPage)
