import React, { useEffect, useState } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import EhblManifestKeys from 'src/constants/locale/key/EhblManifest'
import EhblStatusNotificationApiUrls from 'src/apiUrls/EhblStatusNotificationApiUrls'
import pathMap from 'src/paths/pathMap'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngField from '../../../components/cngcomponents/CngField'
import IconDescButton from '../../../components/button/IconDescButton'
import StatusNotificationDialog from './StatusNotificationDialog'
import ConfirmDialog from '../../common/ConfirmDialog'
import { Box, Divider, Grid } from '@material-ui/core'

const {
  button: { CngButton, CngIconButton }
} = components

const {
  filter: { EQUAL }
} = constants

function StatusNotificationListPage(props) {
  const {
    history,
    hblId,
    //onChangeStep,
    onSetLoading,
    onSubmitManifest,
    showNotification,
    //step
  } = props

  const [data, setData] = useState([])
  const [statusNotificationDialog, setStatusNotificationDialog] = useState({
    open: false,
    statusNotification: null,
    type: 'ADD'
  })
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    statusNotification: null
  })
  const { createRecord, deleteRecord, fetchRecords, updateRecord } =
    useServices()
  const { translate } = useTranslation(Namespace.ACIHWY_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()

  useEffect(() => {
    fetchRecords.execute(
      EhblStatusNotificationApiUrls.GET,
      {
        filters: [{ field: 'hblId', operator: EQUAL, value: hblId }]
      },
      (res) => {
        setData(res.content)
      }
    )
  }, [])

  function makeTranslatedTextsObject() {
    let title = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.StatusNotifyParties.TITLE
    )
    let snType = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.StatusNotifyParties.SN_TYPE
    )
    let email = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.StatusNotifyParties.EMAIL
    )
    let hpNum = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.StatusNotifyParties.HP_NUM
    )
    let deleteButton = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.DELETE_BUTTON
    )
    let saveSuccessMsg = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.SAVE_SUCCESS_MSG
    )

    let updateSuccessMsg = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.UPDATE_SUCCESS_MSG
    )

    let deleteSuccessMsg = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.DELETE_SUCCESS_MSG
    )

    return {
      title,
      snType,
      email,
      hpNum,
      deleteButton,
      saveSuccessMsg,
      updateSuccessMsg,
      deleteSuccessMsg
    }
  }

  function handleAddStatusNotification(datum) {

    console.log("add")
    onSetLoading(true)

    createRecord.execute(
      EhblStatusNotificationApiUrls.POST,
      datum,
      (serverData) => {
        setData((prev) => [...prev, serverData])
        showNotification('success', translatedTextsObject.saveSuccessMsg)
        setStatusNotificationDialog({
          open: false,
          statusNotification: null,
          type: 'ADD'
        })
      },
      (error) => {
        const { errorMessages } = error.response.data

        errorMessages.forEach((message) => {
          showNotification('error', message)
        })
      },
      () => onSetLoading(false)
    )
  }

  function handleEditStatusNotification(datum) {
    onSetLoading(true)

    updateRecord.execute(
      EhblStatusNotificationApiUrls.PUT,
      datum,
      (serverData) => {
        const clonedData = [...data]
        const index = data.findIndex((sn) => sn.id === datum.id)

        clonedData[index] = serverData

        setData(clonedData)
        showNotification('success', translatedTextsObject.updateSuccessMsg)
        setStatusNotificationDialog({
          open: false,
          statusNotification: null,
          type: 'ADD'
        })
      },
      (error) => {
        const { errorMessages } = error.response.data

        errorMessages.forEach((message) => {
          showNotification('error', message)
        })
      },
      () => onSetLoading(false)
    )
  }

  function handleDeleteStatusNotification() {
    const { statusNotification } = confirmDialog

    if (statusNotification) {
      onSetLoading(true)

      deleteRecord.execute(
        EhblStatusNotificationApiUrls.DELETE,
        statusNotification,
        () => {
          setData((prev) =>
            prev.filter((sn) => sn.id !== statusNotification.id)
          )
          setConfirmDialog({ open: false, statusNotification: null })
          showNotification('success', translatedTextsObject.deleteSuccessMsg)
        },
         (error) => {
        const { errorMessages } = error.response.data

        errorMessages.forEach((message) => {
          showNotification('error', message)
        })
      },
        () => onSetLoading(false)
      )
    }
  }

  return (
    <>
      <CngSection title={translatedTextsObject.title} subheader='Optional'>
        <Grid container spacing={2}>
          {data.map((datum, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                     <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.snType}>
                          {datum.snType}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.email}>
                          {datum.email}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.hpNum}>
                          {datum.hpNum}
                        </CngField>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'pen']}
                              onClick={() =>
                                setStatusNotificationDialog({
                                  open: true,
                                  statusNotification: datum,
                                  type: 'EDIT'
                                })
                              }
                              size='small'
                            />
                          </Grid>
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'copy']}
                              onClick={() =>
                                setStatusNotificationDialog({
                                  open: true,
                                  statusNotification: datum,
                                  type: 'ADD'
                                })
                              }
                              size='small'
                            />
                          </Grid>
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'trash']}
                              onClick={() =>
                                setConfirmDialog({
                                  open: true,
                                  statusNotification: datum
                                })
                              }
                              size='small'
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {data.length !== index + 1 && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))}
        </Grid>
        <Box marginTop={2}>
          <IconDescButton
            description='Or clone the previous one'
            label='Add Status Notification'
            iconButtonProps={{
              icon: ['fal', 'plus'],
              onClick: () =>
                setStatusNotificationDialog({
                  open: true,
                  statusNotification: null,
                  type: 'ADD'
                })
            }}
          />
        </Box>
      </CngSection>
      <StatusNotificationDialog
        open={statusNotificationDialog.open}
        onAddStatusNotification={handleAddStatusNotification}
        onClose={() =>
          setStatusNotificationDialog({
            open: false,
            statusNotification: null,
            type: 'ADD'
          })
        }
        onEditStatusNotification={handleEditStatusNotification}
        hblId={hblId}
        showNotification={showNotification}
        statusNotification={statusNotificationDialog.statusNotification}
        type={statusNotificationDialog.type}
      />
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog.open}
        closeDialog={() =>
          setConfirmDialog({ open: false, statusNotification: null })
        }
        confirmDialog={handleDeleteStatusNotification}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
    </>
  )
}

export default StatusNotificationListPage
