import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
import EhblHouseBillKeys from 'src/constants/locale/key/EhblHouseBill'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  const errMsgAlphaNumeric = translate(
    Namespace.EHBL_HOUSE_BILL,
    EhblHouseBillKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC
  )
  const errMsgAlphaNumericWithSpecialChar = translate(
    Namespace.EHBL_HOUSE_BILL,
    EhblHouseBillKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_WITH_SPECIAL_CHAR
  )

  const errMsgAlphaNumericWithSpecialCharNoSpace = translate(
    Namespace.EHBL_HOUSE_BILL,
    EhblHouseBillKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_WITH_SPECIAL_CHAR_NO_SPACE
  )

  const errMsgAlphaNumericWithHypen = translate(
    Namespace.EHBL_HOUSE_BILL,
    EhblHouseBillKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_WITH_HYPEN
  )

  const errMsgNumeric = translate(
    Namespace.EHBL_HOUSE_BILL,
    EhblHouseBillKeys.ValidationMessage.ERROR_MSG_NUMERIC
  )

  const errMsgMinLength = translate(
    Namespace.EHBL_HOUSE_BILL,
    EhblHouseBillKeys.ValidationMessage.ERROR_MSG_MIN_LENGTH
  )
  const errMsgMaxLength = translate(
    Namespace.EHBL_HOUSE_BILL,
    EhblHouseBillKeys.ValidationMessage.ERROR_MSG_MAX_LENGTH
  )

  const errMsgDecimal1 = translate(
    Namespace.EHBL_HOUSE_BILL,
    EhblHouseBillKeys.ValidationMessage.ERROR_MSG_DECIMAL1
  )
  const errMsgDecimal2 = translate(
    Namespace.EHBL_HOUSE_BILL,
    EhblHouseBillKeys.ValidationMessage.ERROR_MSG_DECIMAL2
  )
  const errMsgDecimal3 = translate(
    Namespace.EHBL_HOUSE_BILL,
    EhblHouseBillKeys.ValidationMessage.ERROR_MSG_DECIMAL3
  )

  const regexNumeric = '^[0-9]*$'
  const regexAlphaNumeric = '^[a-zA-Z0-9]*$'
  const regexAlphaNumericWithHypen = '^[a-zA-Z0-9-]*$'
  const regexAlphaNumericWithSpecialChar =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'

  const regexAlphaNumericWithSpecialCharNoSpace =
    '^[a-zA-Z0-9,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\]+$'

  return Yup.object({
    manifestId: Yup.string().nullable(),
    corpid: Yup.string().nullable(),
    movementType: Yup.string().nullable(),
    indicatorMode: Yup.string().nullable(),
    primaryCcn: Yup.string().nullable().required(requiredMessage).matches(regexAlphaNumericWithSpecialCharNoSpace, errMsgAlphaNumericWithSpecialCharNoSpace).max(25, errMsgMaxLength + " 25"),
    carrierCode: Yup.string().nullable(),
    ccn: Yup.string().required(requiredMessage).matches(regexAlphaNumericWithHypen, errMsgAlphaNumericWithHypen).max(25, errMsgMaxLength + " 25"),
    portOfDischargeCode: Yup.string().nullable(),
    portOfDestCode: Yup.string().nullable(),
    messageFuncCode: Yup.string().nullable(),
    consolidationInd: Yup.string().nullable(),
    totalWeight: Yup.lazy((value) =>
      value === '' || value === null
        ? Yup.string().nullable().required(requiredMessage)
        : Yup.string()
            .nullable()
            .required(requiredMessage)
            .matches(
              /^\d{0,13}(\.\d{0,2})?$/,
              errMsgDecimal1 + ' 13 ' + errMsgDecimal2 + ' 2 ' + errMsgDecimal3
            )
            .max(13, errMsgMaxLength + ' 13')
    ),
    status: Yup.string().nullable(),
    closeStatus: Yup.string().nullable(),
    //responseDatetime: Yup.date().validFormat().typeError(dateTypeErrorMessage).nullable(),
    serviceBureauId: Yup.string().nullable(),
    upsClientId: Yup.string().nullable(),
    userRole: Yup.string().nullable(),
    email: Yup.string().nullable(),
    mobileNo: Yup.string().nullable(),
    totalWeightUom: Yup.string().nullable(),
    shipperName: Yup.string().nullable(),
    consigneeName: Yup.string().nullable(),
    entryNo: Yup.string().nullable(),
    amendmentCode: Yup.string().nullable(),
    ucn: Yup.string().nullable(),
    b2bComment: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(256, errMsgMaxLength + " 256"),
    destSubLocCode: Yup.string().nullable(),
    discSubLocCode: Yup.string().nullable(),
    undgContactName: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(70, errMsgMaxLength + " 70"),
    undgContactNo: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(35, errMsgMaxLength + " 35"),
    specialInstruction: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(256, errMsgMaxLength + " 256"),
    isDangerousGoods: Yup.string().nullable(),
    hanInstruction: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(256, errMsgMaxLength + " 256"),
    volume:  Yup.lazy((value) =>
      value === '' || value === null
        ? Yup.string().nullable()
        : Yup.string()
            .nullable()
            .matches(
              /^\d{0,13}(\.\d{0,2})?$/,
              errMsgDecimal1 + ' 13 ' + errMsgDecimal2 + ' 2 ' + errMsgDecimal3
            )
            .max(13, errMsgMaxLength + ' 13')
    ),
    volumeUom: Yup.string().nullable(),
    containerizedInd: Yup.string().nullable(),
    mfInd: Yup.string().nullable(),
    //mfDatetime: Yup.date().validFormat().typeError(dateTypeErrorMessage).nullable(),
    businessName: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(35, errMsgMaxLength + " 35"),
    freightFwdCode: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    uniqueRefNo: Yup.string().nullable(),
    clMsgInd: Yup.string().nullable(),
    prevCcn: Yup.string().nullable().matches(regexAlphaNumericWithSpecialCharNoSpace, errMsgAlphaNumericWithSpecialCharNoSpace).max(25, errMsgMaxLength + " 25"),
    noticeReasonCode: Yup.string().nullable(),
    houseRefNo: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(20, errMsgMaxLength + " 20"),
    usPortOfExit: Yup.string().nullable(),
    usPortOfExitDesc: Yup.string().nullable(),
    frnPortOfLoading: Yup.string().nullable(),
    frnPortOfLoadingDesc: Yup.string().nullable(),
    remarks: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(150, errMsgMaxLength + " 150"),
    printDate: Yup.lazy((value) => value === '' || value === null ? Yup.string().nullable() : Yup.date().nullable().validFormat().typeError(dateTypeErrorMessage)),
    accountOff: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(35, errMsgMaxLength + " 35"),
    storageDate: Yup.lazy((value) => value === '' || value === null ? Yup.string().nullable() : Yup.date().nullable().validFormat().typeError(dateTypeErrorMessage)),
    partyId: Yup.string().nullable(),
    submittedBy: Yup.string().nullable(),
    //submittedDate: Yup.date().validFormat().typeError(dateTypeErrorMessage).nullable(),
    templateName: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(21, errMsgMaxLength + " 21"),
    modeOfSubmission: Yup.string().nullable(),
    containerDetails: Yup.array().nullable(),
    tradeParty: Yup.array().nullable(),
    productDetails: Yup.array().nullable(),
    statusNotifyParties: Yup.array().nullable(),
  })
}

export default makeValidationSchema
