import makeValidationSchema from './StatusNotificationMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React, { useEffect } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import EhblManifestKeys from 'src/constants/locale/key/EhblManifest'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants
} from 'cng-web-lib'
import { useFormContext, useWatch } from "react-hook-form";
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'


const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngSelectField,
      CngLookupAutocompleteField,
      CngCodeMasterAutocompleteField
    },
  },
  CngGridItem,
} = components

const {
  filter: {EQUAL}
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  manifestId: null,
  hblId: null,
  name: "",
  snType: "",
  email: "",
  hpNum: "",
  faxNum: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { setValue, getValues, reset } = useFormContext();
  const { translate } = useTranslation(Namespace.EHBL_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  function makeTranslatedTextsObject() {
    let statusNotification = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.StatusNotifyParties.TITLE
    )
    let snType = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.StatusNotifyParties.SN_TYPE
    )
    let email = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.StatusNotifyParties.EMAIL
    )
    let hpNum = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.StatusNotifyParties.HP_NUM
    )

    return {
      statusNotification,
      snType,
      email,
      hpNum
    }
  }

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.statusNotification} />
      <CardContent>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.snType}>
            <CngCodeMasterAutocompleteField
            required
              name="snType"
              label={translatedTextsObject.snType}
              disabled={disabled}
              codeType='EHBL_SN_TYPE'
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.email}>
            <CngTextField
            required
              name="email"
              label={translatedTextsObject.email}
              disabled={disabled}
              onChange={(e) => {
                setValue("email", e.target.value.toUpperCase(), { shouldValidate: true })
              }}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.hpNum}>
            <CngTextField
              name="hpNum"
              label={translatedTextsObject.hpNum}
              disabled={disabled}
              onChange={(e) => {
                setValue("hpNum", e.target.value.toUpperCase(), { shouldValidate: true })
              }}
              size='small'
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

const StatusNotificationFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default StatusNotificationFormProperties
