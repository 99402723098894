import EhblHouseBillApiUrls from 'src/apiUrls/EhblHouseBillApiUrls'
import FormProperties from './FormProperties'
import { CircularProgress } from '@material-ui/core'
import React, { useEffect, useState,useRef } from 'react'
import { components,useServices,constants } from 'cng-web-lib'
import { useHistory, useParams } from 'react-router-dom'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import pathMap from 'src/paths/pathMap'
import HouseBillViewContent from './HouseBillViewContent'

const {
  form: { CngViewForm },
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL }
} = constants

function ViewPage({ showNotification,houseBillData }) {
  const { id } = useParams()
  const history = useHistory()
  const [data, setData] = useState(null)
  const [lookups, setLookups] = useState(null)
  const { fetchRecord, fetchRecords, securedSendRequest } = useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  useEffect(() => {
    Promise.all([
      // Data
      fetchRecord.execute(
        EhblHouseBillApiUrls.GET,
        id,
        (res) => res.content[0]
      ),
      // Status
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_HB_STATUS' }],
        undefined,
        'code'
      ),
      // Countries
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          {
            field: 'codeType',
            operator: EQUAL,
            value: 'ACIHWY_MANIFEST_COUNTRY'
          }
        ],
        undefined,
        'code'
      ),
      // Country states
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [{ field: 'indicator', operator: 'EQUAL', value: 'ACI' }],
          customData: { codeMType: 'CountryStateCode' }
        },
        (res) => res.content
      ),
      // Party type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_PARTY_TYPE' }],
        undefined,
        'code'
      ),
      // First expected port
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [{ field: 'indicator', operator: 'EQUAL', value: 'HBL' }],
          customData: { codeMType: 'CustomsOffice' }
        },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.code] = item.descriptionEn
          })

          return result
        }
      ),
      // Arrival date time zone
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'SCHEDULER_TIME_ZONE' }],
        undefined,
        'code'
      ),
      // Sublocation
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [{ field: 'indicator', operator: 'EQUAL', value: 'HBL' }],
          customData: { codeMType: 'Sublocation' }
        },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.intlcode] = item.descriptionEn
          })

          return result
        }
      ),
      // Movement type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_MOVEMENT_TYPE' }],
        undefined,
        'code'
      ), //Mode indicator
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_MODE_INDICATOR' }],
        undefined,
        'code'
      ),
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_SNP_TYPE' }],
        undefined,
        'code'
      ),
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_WEIGHT_UOM' }],
        undefined,
        'code'
      ),
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_VOLUME_UOM' }],
        undefined,
        'code'
      ),
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_SN_TYPE' }],
        undefined,
        'code'
      ),
      // Equipment Type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_EQUIPMENT_TYPE' }],
        undefined,
        'code'
      ),
    ]).then(
      ([
        data,
        status,
        countries,
        countryStates,
        partyType,
        customsOffice,
        arrivalDateAndTimeZone,
        sublocation,
        movementType,
        modeIndicator,
        snpType,
        weightUOM,
        volumeUOM,
        snType,
        equipmentType
      ]) => {
        if (data === undefined) {
          showNotification('error', 'Data not found.')
          history.push(pathMap.EHBL_HOUSE_BILL_LIST_VIEW)
        }

        const states = Object.keys(countries).reduce((acc, countryCode) => {
          acc[countryCode] = countryStates.filter(
            (state) => state.code === countryCode
          )

          return acc
        }, {})
        console.log("data",data)

        data = FormProperties.toClientDataFormat(data);
        houseBillData.current = data
        setData(data)
        setLookups({
          status,
          countries,
          countryStates: states,
          partyType,
          customsOffice,
          arrivalDateAndTimeZone,
          sublocation,
          movementType,
          modeIndicator,
          snpType,
          weightUOM,
          volumeUOM,
          snType,
          equipmentType
        })
      }
    )
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function getCountryStateLabel(country, state) {
    if (!lookups || !country || !state) return state

    // Check if country code exists in the lookup
    if (Object.keys(lookups.countries).includes(country)) {
      const result = lookups.countryStates[country].find(
        (countryState) => countryState.intlcode === state
      )

      return result ? result.descriptionEn : state
    } else {
      return state
    }
  }

  if (data === null) {
    return <CircularProgress />
  }

  return (
    <HouseBillViewContent
      data={data}
      getLookupValue={getLookupValue}
      getCountryStateLabel={getCountryStateLabel}
    />
  )
  
}

export default ViewPage
