import React from 'react'
import { components, constants, useTranslation } from 'cng-web-lib'
import FormProperties from './TradePartyFormProperties'
import { Box, Grid, Typography } from '@material-ui/core'
import CngSection from 'src/components/cngcomponents/CngSection'
import CngField from 'src/components/cngcomponents/CngField'
import Namespace from 'src/constants/locale/Namespace'
import EhblHouseBillKeys from 'src/constants/locale/key/EhblHouseBill'
import TinyChip from '../../../components/aciacehighway/TinyChip'

const {
  button: { CngButton, CngPrimaryButton },
  form: {
    CngForm,
    field: {
      CngTextField,
    },
  },
  CngDialog,
  CngGridItem
} = components

const { FormState } = constants

function TradePartyDialog(props) {
  const {
    getLookupValue,
    getCountryStateLabel,
    onAddTradeParty,
    onEditTradeParty,
    isView,
    onClose,
    open,
    showNotification,
    tradeParty
  } = props

  const { initialValues, makeValidationSchema } = FormProperties.formikProps
  const { translate } = useTranslation(Namespace.EHBL_HOUSE_BILL)

  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let tradeParty = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.TITLE
    )
    let hblId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.HBL_ID
    )
    let manifestId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.MANIFEST_ID
    )
    let mfId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.MF_ID
    )
    let corpid = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.CORPID
    )
    let partyId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.PARTY_ID
    )
    let partyName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.PARTY_NAME
    )
    let partyType = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.PARTY_TYPE
    )
    let division = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.DIVISION
    )
    let locPortCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.LOC_PORT_CODE
    )
    let poBoxNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.PO_BOX_NO
    )
    let address = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.ADDRESS
    )
    let city = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.CITY
    )
    let stateCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.STATE_CODE
    )
    let countryCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.COUNTRY_CODE
    )
    let postalCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.POSTAL_CODE
    )
    let contactName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.CONTACT_NAME
    )
    let telephoneNum = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.TELEPHONE_NUM
    )
    let snpType = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.SNP_TYPE
    )
    let snpUcn = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.SNP_UCN
    )
    let snpB2bComment = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.SNP_B2B_COMMENT
    )
    let snpIdentifierValue = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.SNP_IDENTIFIER_VALUE
    )

    let updateButton = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UPDATE_BUTTON
    )

    let saveButton = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SAVE_BUTTON
    )

    let snpAccountSecurityNumber = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.SNP_ACCOUNT_SECURITY_NUMBER
    )

    let snpFreightForwarderCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.SNP_FREIGHT_FORWARDER_CODE
    )

    let snpCbsaCarrierCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.SNP_CBSA_CARRIER_CODE
    )

    let snpWarehouse = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.SNP_WAREHOUSE
    )

    let snpBusinessNumber = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.SNP_BUSINESS_NUMBER
    )

    return {
      tradeParty,
      hblId,
      manifestId,
      mfId,
      corpid,
      partyId,
      partyName,
      partyType,
      division,
      locPortCode,
      poBoxNo,
      address,
      city,
      stateCode,
      countryCode,
      postalCode,
      contactName,
      telephoneNum,
      snpType,
      snpUcn,
      snpB2bComment,
      snpIdentifierValue,
      updateButton,
      saveButton,
      snpIdentifierValue,
      snpAccountSecurityNumber,
      snpFreightForwarderCode,
      snpCbsaCarrierCode,
      snpWarehouse,
      snpBusinessNumber
    }
  }


  if (!open) {
    return null
  }

  let viewContent = tradeParty && (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={4} >
            <CngField label={translatedTextsObject.partyType}>
              <Typography component='div' variant='inherit'>
                {getLookupValue('partyType', tradeParty.partyType)}
                <TinyChip label={tradeParty.partyType} variant='outlined' />
              </Typography>
            </CngField>
          </CngGridItem>
          <CngGridItem xs={12} lg={8}>
            <Grid container spacing={1}>
              <CngGridItem xs={12} sm >
                <CngField label={translatedTextsObject.partyId}>
                  {tradeParty.partyId}
                </CngField>
              </CngGridItem>
            </Grid>
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={4} >

            <CngField label={translatedTextsObject.partyName}>
              {tradeParty.partyName}
            </CngField>
          </CngGridItem>

          {tradeParty.partyType != 'SNP' &&
            <>
              <CngGridItem xs={12} sm={6} lg={4} >
                <CngField label={translatedTextsObject.address}>
                  {tradeParty.address}
                </CngField>
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} >
                <CngField label={translatedTextsObject.city}>
                  {tradeParty.city}
                </CngField>
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} >
                <CngField label={translatedTextsObject.countryCode}>
                  <Typography component='div' variant='inherit'>
                    {getLookupValue('countries', tradeParty.countryCode)}
                    <TinyChip label={tradeParty.countryCode} variant='outlined' />
                  </Typography>
                </CngField>
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} >
                <CngField label={translatedTextsObject.stateCode}>
                  {tradeParty.stateCode && (
                    <Typography component='div' variant='inherit'>
                      {getCountryStateLabel(tradeParty.countryCode, tradeParty.stateCode)}
                      <TinyChip label={tradeParty.stateCode} variant='outlined' />
                    </Typography>
                  )}
                </CngField>
              </CngGridItem>

              <CngGridItem xs={12} sm={6} lg={4} >
                <CngField label={translatedTextsObject.postalCode}>
                  {tradeParty.postalCode}
                </CngField>
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} >
                <CngField label={translatedTextsObject.contactName}>
                  {tradeParty.contactName}
                </CngField>
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} >
                <CngField label={translatedTextsObject.telephoneNum}>
                  {tradeParty.telephoneNum}
                </CngField>
              </CngGridItem>
            </>
          }
          {tradeParty.partyType == 'SNP' &&
            <>
              <CngGridItem xs={12} sm={6} lg={4}>
                <CngField label={translatedTextsObject.snpType}>
                  <Typography component='div' variant='inherit'>
                    {getLookupValue('snpType', tradeParty.snpType)}
                    <TinyChip label={tradeParty.snpType} variant='outlined' />
                  </Typography>
                </CngField>
              </CngGridItem>

              <CngGridItem xs={12} sm={6} lg={4} >
                <CngField label={
                  tradeParty.snpType == 'CB' ? translatedTextsObject.snpAccountSecurityNumber :
                    tradeParty.snpType == 'FW' ? translatedTextsObject.snpFreightForwarderCode :
                      tradeParty.snpType == 'CA' ? translatedTextsObject.snpCbsaCarrierCode :
                        tradeParty.snpType == 'WH' ? translatedTextsObject.snpWarehouse :
                          tradeParty.snpType == 'IM' ? translatedTextsObject.snpBusinessNumber : translatedTextsObject.snpIdentifierValue
                }>
                  {tradeParty.snpIdentifierValue}
                </CngField>
              </CngGridItem>
            </>
          }
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="snpUcn"
              label={translatedTextsObject.snpUcn}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="snpB2bComment"
              label={translatedTextsObject.snpB2bComment}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={6} lg={4} shouldHide={true}>
            <CngTextField
              name="division"
              label={translatedTextsObject.division}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={4} shouldHide={true}>
            <CngTextField
              name="locPortCode"
              label={translatedTextsObject.locPortCode}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={4} shouldHide={true}>
            <CngTextField
              name="poBoxNo"
              label={translatedTextsObject.poBoxNo}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="hblId"
              label={translatedTextsObject.hblId}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="manifestId"
              label={translatedTextsObject.manifestId}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="mfId"
              label={translatedTextsObject.mfId}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="corpid"
              label={translatedTextsObject.corpid}
            />
          </CngGridItem>
        </Grid>
      </Grid>
    </Grid>)

  return (
    <CngDialog
      dialogContent={
        tradeParty && isView ? (
          viewContent
        ) : (
          <CngForm
            fieldLevel='form'
            formikProps={{
              initialValues: tradeParty || initialValues,
              makeValidationSchema: makeValidationSchema,
              onSubmit: (data) => {
                if (tradeParty) {
                  onEditTradeParty(data)
                } else {
                  onAddTradeParty(data)
                }

                onClose()
              }
            }}
            formState={FormState.COMPLETED}
            renderBodySection={(labelMap, shouldHideMap) => (
              <Box padding={1}>
                <FormProperties.Fields
                  showNotification={showNotification}
                  shouldHideMap={shouldHideMap}
                />
              </Box>
            )}
            innerForm={true}
            renderButtonSection={() => (
              <Box display='flex' justifyContent='flex-end'>
                <CngPrimaryButton type='submit'>
                  {tradeParty
                    ? translatedTextsObject.updateButton
                    : translatedTextsObject.saveButton}
                </CngPrimaryButton>
              </Box>
            )}
          />
        )
      }
      dialogAction={
        isView ? <CngButton onClick={onClose}>OK</CngButton> : null
      }
      dialogTitle={translatedTextsObject.tradeParty}
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='lg'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default TradePartyDialog
