import React,  { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFormContext, useWatch } from "react-hook-form";
import { components, DateTimeFormatter, useServices, useTranslation } from 'cng-web-lib'
import { Grid, Card, CardContent, Divider, Typography, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import makeValidationSchema from './MakeValidationSchema'
import Namespace from 'src/constants/locale/Namespace'
import EhblHouseBillKeys from 'src/constants/locale/key/EhblHouseBill'
import { NaCustomsOfficeAutocompleteField, NaSublocationAutocompleteField,NaUSForeignPortKAutocompleteField,NaUSPortAutocompleteField } from 'src/components/na'
import CngSection from '../../../components/cngcomponents/CngSection'
import CollapsibleSection from '../../../components/ui/CollapsibleSection'
import ContainerDetailsTable from './ContainerDetailsTable'
import TradePartyTable from './TradePartyTable'
import ProductDetailsTable from './ProductDetailsTable'
import TradePartyDialog from './TradePartyDialog'
import ContainerDetailsDialog from './ContainerDetailsDialog'
import ProductDetailsDialog from './ProductDetailsDialog'
import TemplateSelectDialogWithOptionEHBL from 'src/components/aciacehighway/TemplateSelectDialogWithOptionEHBL'
import { FileForUserGetUserDetails, FileForUserGetCurrentPartyName } from 'src/common/FileForUserCommon'
import { fetchUser } from "src/views/userprofile/UserProfileService.js"

const {
  button: { CngButton },
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
      CngDateField,
      CngSwitchField,
      CngCodeMasterAutocompleteField,
      CngSelectField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: null,
  manifestId: "",
  corpid: "",
  movementType: "",
  indicatorMode: "",
  primaryCcn: "",
  carrierCode: "",
  ccn: "",
  portOfDischargeCode: "",
  portOfDestCode: "",
  messageFuncCode: "",
  consolidationInd: false,
  totalWeight: "",
  status: "",
  closeStatus: "",
  responseDatetime: "",
  serviceBureauId: "",
  upsClientId: "",
  userRole: "",
  email: "",
  mobileNo: "",
  totalWeightUom: "",
  shipperName: "",
  consigneeName: "",
  entryNo: "",
  amendmentCode: "",
  ucn: "",
  b2bComment: "",
  destSubLocCode: "",
  discSubLocCode: "",
  undgContactName: "",
  undgContactNo: "",
  specialInstruction: "",
  isDangerousGoods: false,
  hanInstruction: "",
  volume: "",
  volumeUom: "",
  containerizedInd: false,
  mfInd: "",
  mfDatetime: "",
  businessName: "",
  freightFwdCode: "",
  uniqueRefNo: "",
  clMsgInd: "",
  prevCcn: "",
  noticeReasonCode: "",
  houseRefNo: "",
  usPortOfExit: "",
  usPortOfExitDesc: "",
  frnPortOfLoading: "",
  frnPortOfLoadingDesc: "",
  remarks: "",
  printDate: "",
  accountOff: "",
  storageDate: "",
  partyId: "",
  submittedBy: "",
  submittedDate: "",
  templateName: "",
  modeOfSubmission: "",
  containerDetails: [],
  tradeParty: [],
  productDetails: [],
  statusNotifyParties: [],
  templateFlag: false
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap, getLookupValue, getCountryStateLabel, autoPopulateBusinessName }) { 
  const { id } = useParams()
  const { translate } = useTranslation(Namespace.EHBL_HOUSE_BILL)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { setValue, getValues, reset, watch, trigger } = useFormContext();
  const { securedSendRequest } = useServices()
  const theme = useTheme()

  let templateName = getValues("templateName");
  const templateFlag = watch("templateFlag")

  const carrierCode = watch("carrierCode")
  const movementType = watch("movementType")
  const indicatorMode = watch("indicatorMode")
  const portOfDischargeCode = watch("portOfDischargeCode")
  const portOfDestCode = watch("portOfDestCode")
  const totalWeightUom = watch("totalWeightUom")
  const destSubLocCode = watch("destSubLocCode")
  const discSubLocCode = watch("discSubLocCode")
  const volumeUom = watch("volumeUom")
  const usPortOfExit = watch("usPortOfExit")
  const frnPortOfLoading = watch("frnPortOfLoading")

  
  const [houseBillTemplateDialog, setHouseBillTemplateDialog] = useState(false)
  const [tradePartyDialog, setTradePartyDialog] = useState({ open: false, tradeParty: null, isView: false })
  const [containerDetailsDialog, setContainerDetailsDialog] = useState({ open: false, containerDetails: null, isView: false })
  const [productDetailsDialog, setProductDetailsDialog] = useState({ open: false, productDetails: null, isView: false })
  const [confirmDialog, setConfirmDialog] = useState({ open: false, houseBill: null })

  const containerDetails = watch('containerDetails')
  const tradeParty = watch('tradeParty')
  const productDetails = watch('productDetails')

  const status = watch("status")

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  const [user, setUser] = useState([]);
  useEffect(() => { 
    let fileForUserDetails = FileForUserGetUserDetails();
    fetchUser(setUser, securedSendRequest,fileForUserDetails); 

    if(templateName!==null &&  templateName!==""){
      setValue("templateFlag",true)
    }
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  // Only auto populate business name on add page
  useEffect(() => {
    if (autoPopulateBusinessName) {
      setValue('businessName', FileForUserGetCurrentPartyName())
    }
  }, [])

  const [scacList, setScacList] = useState([]);
  useEffect(() => {
    var scacCode = "";
    var module = "";
    var jsonArray = [];
    if (user && user.scacs) {
      Object.entries(user.scacs).forEach((item) => {
        for (const key in item[1]) {
          if (key == "scacCode") {
            scacCode = item[1][key];
          }
          if (key == "module") {
            module = item[1][key];
          }
        }

        if (module == "EHBL") {
          var jsonObject = { text: scacCode, value: scacCode };
          jsonArray.push(jsonObject);
        }
      })
      setScacList(jsonArray);

      if(carrierCode == null || carrierCode == undefined || carrierCode == ""){
        if(jsonArray.length>0){
          setValue("carrierCode",jsonArray[0].value)
        }
      }
    }
    if (user && user.userEhblAutoCCNs) {
       let record = user.userEhblAutoCCNs[0];

       if(record && record.autoCcn === 'true'){
        let ccnCurrentSequence = record.currentSequence
        if(getValues("id")==null || getValues("id") == undefined || getValues("id") == ""){
          if(ccnCurrentSequence!=null && ccnCurrentSequence!=undefined && ccnCurrentSequence!=""){
            setValue("ccn",getValues("carrierCode")+ccnCurrentSequence)
          }else{
            setValue("ccn",getValues("carrierCode"))
          }
        }
      }
    }
  }, [user]);

  useEffect(() => {
    let ccn = getValues("ccn")
    let carrierCode = getValues("carrierCode")

    if(carrierCode && ccn && ccn.length>4){
      let ccnCarrierCode = ccn.substring(0,4);
      if(ccnCarrierCode != carrierCode){
        setValue("ccn",carrierCode+ccn.substring(4))
      }
    }
  },[getValues("carrierCode")])

  function handleAddContainerDetails(data) {
    updateSealNo(data)

    const containerDetails = [...getValues('containerDetails'), data].map(
      (containerDetails, index) => ({ ...containerDetails, _id: index + 1 }))
    setValue('containerDetails', containerDetails, { shouldValidate: true })
  }

  function handleDeleteContainerDetails(data) {
    const containerDetails = [...getValues('containerDetails')].filter(
      (containerDetails) => containerDetails.id ? containerDetails.id !== data.id : containerDetails._id !== data._id
    )

    setValue('containerDetails', containerDetails, { shouldValidate: true })
  }

  function handleEditContainerDetails(data) {
    updateSealNo(data)

    const containerDetails = [...getValues('containerDetails')]
    const index = containerDetails.findIndex(
      (containerDetails) => containerDetails.id ? containerDetails.id === data.id : containerDetails._id === data._id
    )
    containerDetails[index] = data

    setValue('containerDetails', containerDetails, { shouldValidate: true })
  }

  function updateSealNo(data){
    let dataSealNoArraylist = [];
    if(data.sealNo1!=null && data.sealNo1!=undefined){
      dataSealNoArraylist.push(data.sealNo1)
    }

    if(data.sealNo2!=null && data.sealNo2!=undefined){
      dataSealNoArraylist.push(data.sealNo2)
    }

    if(data.sealNo3!=null && data.sealNo3!=undefined){
      dataSealNoArraylist.push(data.sealNo3)
    }

    if(data.sealNo4!=null && data.sealNo4!=undefined){
      dataSealNoArraylist.push(data.sealNo4)
    }

    if(data.sealNo5!=null && data.sealNo5!=undefined){
      dataSealNoArraylist.push(data.sealNo5)
    }

    if(data.sealNo6!=null && data.sealNo6!=undefined){
      dataSealNoArraylist.push(data.sealNo6)
    }

    if(data.sealNo7!=null && data.sealNo7!=undefined){
      dataSealNoArraylist.push(data.sealNo7)
    }

    if(data.sealNo8!=null && data.sealNo8!=undefined){
      dataSealNoArraylist.push(data.sealNo8)
    }

    if(data.sealNo9!=null && data.sealNo9!=undefined){
      dataSealNoArraylist.push(data.sealNo9)
    }

    if(data.sealNo10!=null && data.sealNo10!=undefined){
      dataSealNoArraylist.push(data.sealNo10)
    }

    if(data.sealNo11!=null && data.sealNo11!=undefined){
      dataSealNoArraylist.push(data.sealNo11)
    }

    if(data.sealNo12!=null && data.sealNo12!=undefined){
      dataSealNoArraylist.push(data.sealNo12)
    }

    if(data.sealNo13!=null && data.sealNo13!=undefined){
      dataSealNoArraylist.push(data.sealNo13)
    }

    if(data.sealNo14!=null && data.sealNo14!=undefined){
      dataSealNoArraylist.push(data.sealNo14)
    }

    if(data.sealNo15!=null && data.sealNo15!=undefined){
      dataSealNoArraylist.push(data.sealNo15)
    }

    if(data.sealNo16!=null && data.sealNo16!=undefined){
      dataSealNoArraylist.push(data.sealNo16)
    }

    if(data.sealNo17!=null && data.sealNo17!=undefined){
      dataSealNoArraylist.push(data.sealNo17)
    }

    if(data.sealNo18!=null && data.sealNo18!=undefined){
      dataSealNoArraylist.push(data.sealNo18)
    }

    if(data.sealNo19!=null && data.sealNo19!=undefined){
      dataSealNoArraylist.push(data.sealNo19)
    }

    if(data.sealNo20!=null && data.sealNo20!=undefined){
      dataSealNoArraylist.push(data.sealNo20)
    }

    if(data.sealNo21!=null && data.sealNo21!=undefined){
      dataSealNoArraylist.push(data.sealNo21)
    }

    if(data.sealNo22!=null && data.sealNo22!=undefined){
      dataSealNoArraylist.push(data.sealNo22)
    }

    if(data.sealNo23!=null && data.sealNo23!=undefined){
      dataSealNoArraylist.push(data.sealNo23)
    }

    if(data.sealNo24!=null && data.sealNo24!=undefined){
      dataSealNoArraylist.push(data.sealNo24)
    }

    if(data.sealNo25!=null && data.sealNo25!=undefined){
      dataSealNoArraylist.push(data.sealNo25)
    }

    if(data.sealNo26!=null && data.sealNo26!=undefined){
      dataSealNoArraylist.push(data.sealNo26)
    }

    if(data.sealNo27!=null && data.sealNo27!=undefined){
      dataSealNoArraylist.push(data.sealNo27)
    }

    if(data.sealNo28!=null && data.sealNo28!=undefined){
      dataSealNoArraylist.push(data.sealNo28)
    }

    if(data.sealNo29!=null && data.sealNo29!=undefined){
      dataSealNoArraylist.push(data.sealNo29)
    }

    if(data.sealNo30!=null && data.sealNo30!=undefined){
      dataSealNoArraylist.push(data.sealNo30)
    }
   
    if(data.sealNo31!=null && data.sealNo31!=undefined){
      dataSealNoArraylist.push(data.sealNo31)
    }

    if(data.sealNo32!=null && data.sealNo32!=undefined){
      dataSealNoArraylist.push(data.sealNo32)
    }

    if(data.sealNo33!=null && data.sealNo33!=undefined){
      dataSealNoArraylist.push(data.sealNo33)
    }

    if(data.sealNo34!=null && data.sealNo34!=undefined){
      dataSealNoArraylist.push(data.sealNo34)
    }

    if(data.sealNo35!=null && data.sealNo35!=undefined){
      dataSealNoArraylist.push(data.sealNo35)
    }

    if(data.sealNo36!=null && data.sealNo36!=undefined){
      dataSealNoArraylist.push(data.sealNo36)
    }

    if(data.sealNo37!=null && data.sealNo38!=undefined){
      dataSealNoArraylist.push(data.sealNo38)
    }

    if(data.sealNo38!=null && data.sealNo38!=undefined){
      dataSealNoArraylist.push(data.sealNo38)
    }

    if(data.sealNo39!=null && data.sealNo39!=undefined){
      dataSealNoArraylist.push(data.sealNo39)
    }

    if(data.sealNo40!=null && data.sealNo40!=undefined){
      dataSealNoArraylist.push(data.sealNo40)
    }

    if(dataSealNoArraylist.length>0){
      data.sealNo = dataSealNoArraylist.join(":");
    }else{
      data.sealNo = ""
    }
  }

  function handleCloneTradeParty(data) {
    const tradeParty = [...getValues('tradeParty'), data].map(
      (tradeParty, index) => ({ ...tradeParty, _id: index + 1 }))

    setValue('tradeParty', tradeParty, { shouldValidate: true })
  }

  function handleAddTradeParty(data) {
    const tradeParty = [...getValues('tradeParty'), data].map(
      (tradeParty, index) => ({ ...tradeParty, _id: index + 1 }))

    setValue('tradeParty', tradeParty, { shouldValidate: true })
  }

  function handleDeleteTradeParty(data) {
    const tradeParty = [...getValues('tradeParty')].filter(
      (tradeParty) => tradeParty.id ? tradeParty.id !== data.id : tradeParty._id !== data._id
    )

    setValue('tradeParty', tradeParty, { shouldValidate: true })
  }

  function handleEditTradeParty(data) {
    const tradeParty = [...getValues('tradeParty')]
    const index = tradeParty.findIndex(
      (tradeParty) => tradeParty.id ? tradeParty.id === data.id : tradeParty._id === data._id
    )
    tradeParty[index] = data

    setValue('tradeParty', tradeParty, { shouldValidate: true })
  }

  function concatUndgCode(data){
    if(data.undgCodeList!=null && data.undgCodeList!=undefined){

      let undgCodeStringList = []
      for (const undgCode of data.undgCodeList) {
        undgCodeStringList.push(undgCode.undgCodeValue)
      }
      data.undgCode = undgCodeStringList.join("|")
    }
  }

  function handleAddProductDetails(data) {
    concatUndgCode(data)

    const productDetails = [...getValues('productDetails'), data].map(
      (productDetails, index) => ({ ...productDetails, _id: index + 1 }))

    setValue('productDetails', productDetails, { shouldValidate: true })
  }

  function handleDeleteProductDetails(data) {
    const productDetails = [...getValues('productDetails')].filter(
      (productDetails) => productDetails.id ? productDetails.id !== data.id : productDetails._id !== data._id
    )

    setValue('productDetails', productDetails, { shouldValidate: true })
  }

  function handleEditProductDetails(data) {
    concatUndgCode(data)
    const productDetails = [...getValues('productDetails')]
    const index = productDetails.findIndex(
      (productDetails) => productDetails.id ? productDetails.id === data.id : productDetails._id === data._id
    )
    productDetails[index] = data

    setValue('productDetails', productDetails, { shouldValidate: true })
  }

  function checkA8A() {
    let usPortOfExit = getValues("usPortOfExit")
    if(usPortOfExit !=null && usPortOfExit!=undefined && usPortOfExit!=""){
      return true;
    }

    let frnPortOfLoading = getValues("frnPortOfLoading")
    if(frnPortOfLoading !=null && frnPortOfLoading!=undefined && frnPortOfLoading!=""){
      return true;
    }

    let printDate = getValues("printDate")
    if(printDate !=null && printDate!=undefined && printDate!=""){
      return true;
    }

    let accountOff = getValues("accountOff")
    if(accountOff !=null && accountOff!=undefined && accountOff!=""){
      return true;
    }

    let storageDate = getValues("usPortOfExit")
    if(storageDate !=null && storageDate!=undefined && storageDate!=""){
      return true;
    }

    return false;
  }

  function makeTranslatedTextsObject() {
    let ehblHouseBill = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TITLE
    )
    let manifestId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MANIFEST_ID
    )
    let corpid = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CORPID
    )
    let movementType = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MOVEMENT_TYPE
    )
    let indicatorMode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.INDICATOR_MODE
    )
    let primaryCcn = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PRIMARY_CCN
    )
    let carrierCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CARRIER_CODE
    )
    let ccn = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CCN
    )
    let portOfDischargeCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PORT_OF_DISCHARGE_CODE
    )
    let portOfDestCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PORT_OF_DEST_CODE
    )
    let messageFuncCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MESSAGE_FUNC_CODE
    )
    let consolidationInd = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CONSOLIDATION_IND
    )
    let totalWeight = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TOTAL_WEIGHT
    )
    let status = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.STATUS
    )
    let closeStatus = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CLOSE_STATUS
    )
    let responseDatetime = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.RESPONSE_DATETIME
    )
    let serviceBureauId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SERVICE_BUREAU_ID
    )
    let upsClientId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UPS_CLIENT_ID
    )
    let userRole = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.USER_ROLE
    )
    let email = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.EMAIL
    )
    let mobileNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MOBILE_NO
    )
    let totalWeightUom = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TOTAL_WEIGHT_UOM
    )
    let shipperName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SHIPPER_NAME
    )
    let consigneeName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CONSIGNEE_NAME
    )
    let entryNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ENTRY_NO
    )
    let amendmentCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.AMENDMENT_CODE
    )
    let ucn = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UCN
    )
    let b2bComment = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.B2B_COMMENT
    )
    let destSubLocCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.DEST_SUB_LOC_CODE
    )
    let discSubLocCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.DISC_SUB_LOC_CODE
    )
    let undgContactName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UNDG_CONTACT_NAME
    )
    let undgContactNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UNDG_CONTACT_NO
    )
    let specialInstruction = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SPECIAL_INSTRUCTION
    )
    let isDangerousGoods = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.IS_DANGEROUS_GOODS
    )
    let hanInstruction = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.HAN_INSTRUCTION
    )
    let volume = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.VOLUME
    )
    let volumeUom = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.VOLUME_UOM
    )
    let containerizedInd = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CONTAINERIZED_IND
    )
    let mfInd = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MF_IND
    )
    let mfDatetime = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MF_DATETIME
    )
    let businessName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.BUSINESS_NAME
    )
    let freightFwdCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.FREIGHT_FWD_CODE
    )
    let uniqueRefNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UNIQUE_REF_NO
    )
    let clMsgInd = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CL_MSG_IND
    )
    let prevCcn = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PREV_CCN
    )
    let noticeReasonCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.NOTICE_REASON_CODE
    )
    let houseRefNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.HOUSE_REF_NO
    )
    let usPortOfExit = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.US_PORT_OF_EXIT
    )
    let usPortOfExitDesc = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.US_PORT_OF_EXIT_DESC
    )
    let frnPortOfLoading = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.FRN_PORT_OF_LOADING
    )
    let frnPortOfLoadingDesc = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.FRN_PORT_OF_LOADING_DESC
    )
    let remarks = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.REMARKS
    )
    let printDate = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PRINT_DATE
    )
    let accountOff = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ACCOUNT_OFF
    )
    let storageDate = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.STORAGE_DATE
    )
    let partyId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PARTY_ID
    )
    let submittedBy = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SUBMITTED_BY
    )
    let submittedDate = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SUBMITTED_DATE
    )
    let templateName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TEMPLATE_NAME
    )
    let modeOfSubmission = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MODE_OF_SUBMISSION
    )
    let cargoHeader = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.CARGO_HEADER
    )

    let containerDetails = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.TITLE
    )
    let hblId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.HBL_ID
    )
    let mfId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.MF_ID
    )
    let equipmentId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.EQUIPMENT_ID
    )
    let equipmentType = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.EQUIPMENT_TYPE
    )
    let sealNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO
    )
    let tradeParty = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.TITLE
    )
    let partyName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.PARTY_NAME
    )
    let partyType = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.PARTY_TYPE
    )
    let division = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.DIVISION
    )
    let locPortCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.LOC_PORT_CODE
    )
    let poBoxNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.PO_BOX_NO
    )
    let address = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.ADDRESS
    )
    let city = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.CITY
    )
    let stateCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.STATE_CODE
    )
    let countryCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.COUNTRY_CODE
    )
    let postalCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.POSTAL_CODE
    )
    let contactName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.CONTACT_NAME
    )
    let telephoneNum = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.TELEPHONE_NUM
    )
    let snpType = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.SNP_TYPE
    )
    let snpUcn = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.SNP_UCN
    )
    let snpB2bComment = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.SNP_B2B_COMMENT
    )
    let snpIdentifierValue = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TradeParty.SNP_IDENTIFIER_VALUE
    )

    let cargoDetails = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.CARGO_DETAILS
    )

    let productDetails = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.TITLE
    )
    let quantity = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.QUANTITY
    )
    let uom = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.UOM
    )
    let description = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.DESCRIPTION
    )
    let marksAndNumbers = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.MARKS_AND_NUMBERS
    )
    let hsCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.HS_CODE
    )
    let undgCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.UNDG_CODE
    )
    let weight = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.WEIGHT
    )
    let weightUom = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ProductDetails.WEIGHT_UOM
    )
    let statusNotifyParties = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.StatusNotifyParties.TITLE
    )
    let name = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.StatusNotifyParties.NAME
    )
    let snType = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.StatusNotifyParties.SN_TYPE
    )
    let hblCcn = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.StatusNotifyParties.HBL_CCN
    )
    let hpNum = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.StatusNotifyParties.HP_NUM
    )
    let module = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.StatusNotifyParties.MODULE
    )

    let printA8A = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PRINT_A8A
    )

    let printA8AFooterText = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PRINT_A8A_FOOTER_TEXT
    )

    let printA8AHideText = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PRINT_A8A_HIDE_TEXT
    )

    let printA8AShowText = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PRINT_A8A_SHOW_TEXT
    )

    let houseBillTemplate = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.HOUSE_BILL_TEMPLATE
    )


    return {
      ehblHouseBill,
      manifestId,
      corpid,
      movementType,
      indicatorMode,
      primaryCcn,
      carrierCode,
      ccn,
      portOfDischargeCode,
      portOfDestCode,
      messageFuncCode,
      consolidationInd,
      totalWeight,
      status,
      closeStatus,
      responseDatetime,
      serviceBureauId,
      upsClientId,
      userRole,
      email,
      mobileNo,
      totalWeightUom,
      shipperName,
      consigneeName,
      entryNo,
      amendmentCode,
      ucn,
      b2bComment,
      destSubLocCode,
      discSubLocCode,
      undgContactName,
      undgContactNo,
      specialInstruction,
      isDangerousGoods,
      hanInstruction,
      volume,
      volumeUom,
      containerizedInd,
      mfInd,
      mfDatetime,
      businessName,
      freightFwdCode,
      uniqueRefNo,
      clMsgInd,
      prevCcn,
      noticeReasonCode,
      houseRefNo,
      usPortOfExit,
      usPortOfExitDesc,
      frnPortOfLoading,
      frnPortOfLoadingDesc,
      remarks,
      printDate,
      accountOff,
      storageDate,
      partyId,
      submittedBy,
      submittedDate,
      templateName,
      modeOfSubmission,
      cargoHeader,
      containerDetails,
      hblId,
      mfId,
      equipmentId,
      equipmentType,
      sealNo,
      tradeParty,
      partyName,
      partyType,
      division,
      locPortCode,
      poBoxNo,
      address,
      city,
      stateCode,
      countryCode,
      postalCode,
      contactName,
      telephoneNum,
      snpType,
      snpUcn,
      snpB2bComment,
      snpIdentifierValue,
      cargoDetails,
      productDetails,
      quantity,
      uom,
      description,
      marksAndNumbers,
      hsCode,
      undgCode,
      weight,
      weightUom,
      statusNotifyParties,
      name,
      snType,
      hblCcn,
      hpNum,
      module,
      printA8A,
      printA8AFooterText,
      printA8AHideText,
      printA8AShowText,
      houseBillTemplate
    }
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>

          <Card variant='outlined' style={{ padding: 16 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm="auto">
                <CngButton
                  onClick={() => setHouseBillTemplateDialog(true)}
                  size='medium'
                  startIcon={<FontAwesomeIcon icon={['fal', 'money-check-edit']} />}
                  variant='outlined'
                  style={{ borderColor: theme.palette.divider, color: theme.palette.grey[500] }}
                >
                  Use template
                </CngButton>
              </Grid>

              <Grid item xs={12} sm="auto">
                <Divider orientation="vertical" />
              </Grid>

              <Grid item xs={12} sm>
                <Grid container spacing={2} alignItems="center">
                  <CngGridItem xs={12} sm="auto" shouldHide={shouldHideMap.templateFlag}>
                    <CngCheckboxField
                      disabled={disabled}
                      label={
                        <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                          {translatedTextsObject.templateFlag}
                        </Typography>
                      }
                      name="templateFlag"
                      size='small'
                      style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                      onChange={(e) => setValue('templateFlag', e.target.checked, { shouldValidate: true })}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm shouldHide={shouldHideMap.templateName}>
                    <CngTextField
                      required={getValues("templateFlag")}
                      name="templateName"
                      inputProps={{ maxLength: 50 }}
                      placeholder={translatedTextsObject.templateName}
                      disabled={disabled || templateFlag === false}
                      onChange={(e) => { setValue("templateName", e.target.value.toUpperCase(), { shouldValidate: true }) }}
                      onBlur={(e) => {
                        if (e.target.value !== undefined) {
                          setValue("templateName", e.target.value.trim(), { shouldValidate: true })
                        }
                      }}
                    />
                  </CngGridItem>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <CngSection
            title={translatedTextsObject.ehblHouseBill}
            subheader={
              <>
                <Typography color="error" component="span" variant="caption">*</Typography>
                <Typography color="textSecondary" component="span" variant="caption">Mandatory fields</Typography>
              </>
            }
          >
            <CardContent>
              <Grid container spacing={1}>
                <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.houseRefNo}>
                  <CngTextField
                    name="houseRefNo"
                    label={translatedTextsObject.houseRefNo}
                    disabled={disabled}
                    size='small'
                    onChange={(e) => { setValue("houseRefNo", e.target.value.toUpperCase(), { shouldValidate: true }) }}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.movementType}>
                  <CngCodeMasterAutocompleteField
                    required
                    name="movementType"
                    label={translatedTextsObject.movementType}
                    disabled={disabled}
                    codeType='EHBL_MOVEMENT_TYPE'
                    size='small'
                    key={movementType}
                  />
                </CngGridItem>

                <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.indicatorMode}>
                  <CngCodeMasterAutocompleteField
                    required
                    name="indicatorMode"
                    label={translatedTextsObject.indicatorMode}
                    disabled={disabled}
                    codeType='EHBL_MODE_INDICATOR'
                    size='small'
                    key={indicatorMode}
                  />
                </CngGridItem>

                <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.carrierCode}>
                  <CngSelectField
                    required
                    name="carrierCode"
                    label={translatedTextsObject.carrierCode}
                    disabled={disabled}
                    items={scacList}
                    size='small'
                    key={carrierCode}
                  />
                </CngGridItem>

                <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.portOfDestCode}>
                  <NaCustomsOfficeAutocompleteField
                    required
                    name="portOfDestCode"
                    label={translatedTextsObject.portOfDestCode}
                    disabled={disabled}
                    size='small'
                    key={portOfDestCode}
                    lookupProps={{ filters: [{ field: 'indicator', operator: 'equal', value: 'HBL' }] }}
                  />
                </CngGridItem>

                <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.destSubLocCode}>
                  <NaSublocationAutocompleteField
                    required
                    name="destSubLocCode"
                    label={translatedTextsObject.destSubLocCode}
                    disabled={disabled}
                    size='small'
                    key={destSubLocCode}
                    lookupProps={{ filters: [{ field: 'indicator', operator: 'equal', value: 'HBL' }] }}
                  />
                </CngGridItem>

                <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.ccn}>
                  <CngTextField
                    required
                    name="ccn"
                    label={translatedTextsObject.ccn}
                    disabled={status == "AC"? true : disabled}
                    size='small'
                    onChange={(e) => { setValue("ccn", e.target.value.toUpperCase(), { shouldValidate: true }) }}
                  />
                </CngGridItem>

                <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.portOfDischargeCode}>
                  <NaCustomsOfficeAutocompleteField
                    name="portOfDischargeCode"
                    label={translatedTextsObject.portOfDischargeCode}
                    disabled={disabled}
                    size='small'
                    key={portOfDischargeCode}
                    lookupProps={{ filters: [{ field: 'indicator', operator: 'equal', value: 'HBL' }] }}
                  />
                </CngGridItem>

                <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.discSubLocCode}>
                  <NaSublocationAutocompleteField
                    name="discSubLocCode"
                    label={translatedTextsObject.discSubLocCode}
                    disabled={disabled}
                    size='small'
                    key={discSubLocCode}
                    lookupProps={{ filters: [{ field: 'indicator', operator: 'equal', value: 'HBL' }] }}
                  />
                </CngGridItem>

                <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.primaryCcn}>
                  <CngTextField
                    required
                    name="primaryCcn"
                    label={translatedTextsObject.primaryCcn}
                    disabled={disabled}
                    size='small'
                    onChange={(e) => { setValue("primaryCcn", e.target.value.toUpperCase(), { shouldValidate: true }) }}
                  />
                </CngGridItem>

                <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.businessName}>
                  <CngTextField
                    name="businessName"
                    label={translatedTextsObject.businessName}
                    disabled={disabled}
                    size='small'
                    onChange={(e) => { setValue("businessName", e.target.value.toUpperCase(), { shouldValidate: true }) }}
                  />
                </CngGridItem>

                <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.freightFwdCode}>
                  <CngTextField
                    name="freightFwdCode"
                    label={translatedTextsObject.freightFwdCode}
                    disabled={disabled}
                    size='small'
                    onChange={(e) => { setValue("freightFwdCode", e.target.value.toUpperCase(), { shouldValidate: true }) }}
                  />
                </CngGridItem>

                <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.prevCcn}>
                  <CngTextField
                    name="prevCcn"
                    label={translatedTextsObject.prevCcn}
                    disabled={disabled}
                    size='small'
                    onChange={(e) => { setValue("prevCcn", e.target.value.toUpperCase(), { shouldValidate: true }) }}
                  />
                </CngGridItem>

                <CngGridItem xs={12} sm={6} lg={8} shouldHide={shouldHideMap.consolidationInd}>
                  <CngSwitchField
                    name="consolidationInd"
                    label={translatedTextsObject.consolidationInd}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>

                <CngGridItem xs={12} sm={12} lg={12} shouldHide={shouldHideMap.remarks}>
                  <CngTextField
                    name="remarks"
                    label={translatedTextsObject.remarks}
                    disabled={disabled}
                    size='small'
                    onChange={(e) => { setValue("remarks", e.target.value.toUpperCase(), { shouldValidate: true }) }}
                  />
                </CngGridItem>

                <Grid item xs={12}>
                  <CollapsibleSection
                    title={translatedTextsObject.printA8A}
                    defaultExpanded={() => checkA8A()}
                    footerText={translatedTextsObject.printA8AFooterText}
                    hideText={translatedTextsObject.printA8AHideText}
                    showText={translatedTextsObject.printA8AShowText}
                  >
                    <Grid container spacing={1}>
                      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.usPortOfExit}>
                        <NaUSPortAutocompleteField
                          name="usPortOfExit"
                          label={translatedTextsObject.usPortOfExit}
                          disabled={disabled}
                          size='small'
                          key={usPortOfExit}
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.frnPortOfLoading}>
                        <NaUSForeignPortKAutocompleteField
                          name="frnPortOfLoading"
                          label={translatedTextsObject.frnPortOfLoading}
                          disabled={disabled}
                          size='small'
                          key={frnPortOfLoading}
                        />
                      </CngGridItem>

                      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.accountOff}>
                        <CngTextField
                          name="accountOff"
                          label={translatedTextsObject.accountOff}
                          disabled={disabled}
                          size='small'
                          inputProps={{ maxLength: 35 }}
                          onChange={(e) => { setValue("accountOff", e.target.value.toUpperCase(), { shouldValidate: true }) }}
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.printDate}>
                        <CngDateField
                          name="printDate"
                          label={translatedTextsObject.printDate}
                          disabled={disabled}
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.storageDate}>
                        <CngDateField
                          name="storageDate"
                          label={translatedTextsObject.storageDate}
                          disabled={disabled}
                          size='small'
                        />
                      </CngGridItem>
                    </Grid>
                  </CollapsibleSection>
                </Grid>

                <Grid item xs={12}>
                  <CngSection title={translatedTextsObject.tradeParty}>
                    <TradePartyTable
                      data={tradeParty}
                      onAddTradeParty={() => setTradePartyDialog({ open: true, tradeParty: null, isView: false })}
                      onEditTradeParty={(tradeParty) => setTradePartyDialog({ open: true, tradeParty, isView: false })}
                      onDeleteTradeParty={handleDeleteTradeParty}
                      showNotification={showNotification}
                      onCloneTradeParty={handleCloneTradeParty}
                    />
                    <TradePartyDialog
                      tradeParty={tradePartyDialog.tradeParty}
                      isView={tradePartyDialog.isView}
                      onAddTradeParty={handleAddTradeParty}
                      onEditTradeParty={handleEditTradeParty}
                      open={tradePartyDialog.open}
                      onClose={() =>
                        setTradePartyDialog({ open: false, tradeParty: null, isView: false })
                      }
                      showNotification={showNotification}
                      getLookupValue={getLookupValue}
                      getCountryStateLabel={getCountryStateLabel}
                    />
                    <CngGridItem xs={12} sm={12} shouldHide={shouldHideMap.b2bComment}>
                      <CngTextField
                        multiline
                        rows={4}
                        name="b2bComment"
                        label={translatedTextsObject.b2bComment}
                        disabled={disabled}
                        onChange={(e) => { setValue('b2bComment', e.target.value.toUpperCase().replace(/(\r\n|\r|\n)+/g, ' ')) }}
                      />
                    </CngGridItem>
                  </CngSection>
                </Grid>

                <Grid item xs={12}>

                  <CngSection title={translatedTextsObject.cargoHeader}>
                    <Grid container spacing={1}>
                      <CngGridItem xs={12} sm={12} shouldHide={shouldHideMap.containerizedInd}>
                        <CngSwitchField
                          name="containerizedInd"
                          label={translatedTextsObject.containerizedInd}
                          disabled={disabled}
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.hanInstruction}>
                        <CngTextField
                          name="hanInstruction"
                          label={translatedTextsObject.hanInstruction}
                          disabled={disabled}
                          size='small'
                          onChange={(e) => {
                            setValue("hanInstruction", e.target.value.toUpperCase(), { shouldValidate: true })
                          }}
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.totalWeight}>
                        <CngTextField
                          required
                          name="totalWeight"
                          label={translatedTextsObject.totalWeight}
                          disabled={disabled}
                          size='small'
                          onChange={(e) => {
                            setValue("totalWeight", e.target.value.toUpperCase(), { shouldValidate: true })
                          }}
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.totalWeightUom}>
                        <CngCodeMasterAutocompleteField
                          required
                          name="totalWeightUom"
                          label={translatedTextsObject.totalWeightUom}
                          disabled={disabled}
                          codeType='EHBL_WEIGHT_UOM'
                          size='small'
                          key={totalWeightUom}
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.isDangerousGoods}>
                        <CngSwitchField
                          name="isDangerousGoods"
                          label={translatedTextsObject.isDangerousGoods}
                          disabled={disabled}
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.volume}>
                        <CngTextField
                          name="volume"
                          label={translatedTextsObject.volume}
                          disabled={disabled}
                          size='small'
                          onChange={(e) => {
                            setValue("volume", e.target.value.toUpperCase(), { shouldValidate: true })
                          }}
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.volumeUom}>
                        <CngCodeMasterAutocompleteField
                          name="volumeUom"
                          label={translatedTextsObject.volumeUom}
                          disabled={disabled}
                          codeType='EHBL_VOLUME_UOM'
                          size='small'
                          key={volumeUom}
                        />
                      </CngGridItem>

                      {getValues("isDangerousGoods") && <>
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.undgContactName}>
                              <CngTextField
                                name="undgContactName"
                                label={translatedTextsObject.undgContactName}
                                disabled={disabled}
                                size='small'
                                onChange={(e) => {
                                  setValue("undgContactName", e.target.value.toUpperCase(), { shouldValidate: true })
                                }}
                              />
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.undgContactNo}>
                              <CngTextField
                                name="undgContactNo"
                                label={translatedTextsObject.undgContactNo}
                                disabled={disabled}
                                size='small'
                                onChange={(e) => {
                                  setValue("undgContactNo", e.target.value.toUpperCase(), { shouldValidate: true })
                                }}
                              />
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.specialInstruction}>
                              <CngTextField
                                name="specialInstruction"
                                label={translatedTextsObject.specialInstruction}
                                disabled={disabled}
                                size='small'
                                onChange={(e) => {
                                  setValue("specialInstruction", e.target.value.toUpperCase(), { shouldValidate: true })
                                }}
                              />
                            </CngGridItem>
                          </Grid>
                        </Grid>
                      </>}
                      {getValues("containerizedInd") &&
                        <Grid item xs={12}>
                          <CngSection title={translatedTextsObject.containerDetails}>
                            <ContainerDetailsTable
                              data={containerDetails}
                              onAddContainerDetails={() => setContainerDetailsDialog({ open: true, containerDetails: null, isView: false })}
                              onEditContainerDetails={(containerDetails) => setContainerDetailsDialog({ open: true, containerDetails, isView: false })}
                              onDeleteContainerDetails={handleDeleteContainerDetails}
                              showNotification={showNotification}
                            />
                          </CngSection>
                        </Grid>
                      }
                      <ContainerDetailsDialog
                        containerDetails={containerDetailsDialog.containerDetails}
                        isView={containerDetailsDialog.isView}
                        onAddContainerDetails={handleAddContainerDetails}
                        onEditContainerDetails={handleEditContainerDetails}
                        open={containerDetailsDialog.open}
                        onClose={() =>
                          setContainerDetailsDialog({ open: false, containerDetails: null, isView: false })
                        }
                        showNotification={showNotification}
                      />

                    </Grid>
                  </CngSection>
                </Grid>

                <Grid item xs={12}>
                  <CngSection title={translatedTextsObject.cargoDetails}>
                    <ProductDetailsTable
                      data={productDetails}
                      onAddProductDetails={() => setProductDetailsDialog({ open: true, productDetails: null, isView: false })}
                      onEditProductDetails={(productDetails) => setProductDetailsDialog({ open: true, productDetails, isView: false })}
                      onDeleteProductDetails={handleDeleteProductDetails}
                      showNotification={showNotification}
                    />
                    <ProductDetailsDialog
                      productDetails={productDetailsDialog.productDetails}
                      isView={productDetailsDialog.isView}
                      onAddProductDetails={handleAddProductDetails}
                      onEditProductDetails={handleEditProductDetails}
                      open={productDetailsDialog.open}
                      onClose={() =>
                        setProductDetailsDialog({ open: false, productDetails: null, isView: false })
                      }
                      showNotification={showNotification}
                      getLookupValue={getLookupValue}
                    />
                  </CngSection>
                </Grid>


                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="frnPortOfLoadingDesc"
                    label={translatedTextsObject.frnPortOfLoadingDesc}
                    disabled={disabled}
                  />
                </CngGridItem>

                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="manifestId"
                    label={translatedTextsObject.manifestId}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="corpid"
                    label={translatedTextsObject.corpid}
                    disabled={disabled}
                  />
                </CngGridItem>

                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="messageFuncCode"
                    label={translatedTextsObject.messageFuncCode}
                    disabled={disabled}
                  />
                </CngGridItem>


                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="status"
                    label={translatedTextsObject.status}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="closeStatus"
                    label={translatedTextsObject.closeStatus}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngDateField
                    name="responseDatetime"
                    label={translatedTextsObject.responseDatetime}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="serviceBureauId"
                    label={translatedTextsObject.serviceBureauId}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="upsClientId"
                    label={translatedTextsObject.upsClientId}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="userRole"
                    label={translatedTextsObject.userRole}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="email"
                    label={translatedTextsObject.email}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="mobileNo"
                    label={translatedTextsObject.mobileNo}
                    disabled={disabled}
                  />
                </CngGridItem>

                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="shipperName"
                    label={translatedTextsObject.shipperName}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="consigneeName"
                    label={translatedTextsObject.consigneeName}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="entryNo"
                    label={translatedTextsObject.entryNo}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="amendmentCode"
                    label={translatedTextsObject.amendmentCode}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="ucn"
                    label={translatedTextsObject.ucn}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="b2bComment"
                    label={translatedTextsObject.b2bComment}
                    disabled={disabled}
                  />
                </CngGridItem>

                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="mfInd"
                    label={translatedTextsObject.mfInd}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngDateField
                    name="mfDatetime"
                    label={translatedTextsObject.mfDatetime}
                    disabled={disabled}
                  />
                </CngGridItem>


                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="uniqueRefNo"
                    label={translatedTextsObject.uniqueRefNo}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="clMsgInd"
                    label={translatedTextsObject.clMsgInd}
                    disabled={disabled}
                  />
                </CngGridItem>

                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="noticeReasonCode"
                    label={translatedTextsObject.noticeReasonCode}
                    disabled={disabled}
                  />
                </CngGridItem>


                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="usPortOfExitDesc"
                    label={translatedTextsObject.usPortOfExitDesc}
                    disabled={disabled}
                  />
                </CngGridItem>

                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="partyId"
                    label={translatedTextsObject.partyId}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="submittedBy"
                    label={translatedTextsObject.submittedBy}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngDateField
                    name="submittedDate"
                    label={translatedTextsObject.submittedDate}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="templateName"
                    label={translatedTextsObject.templateName}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={true}>
                  <CngTextField
                    name="modeOfSubmission"
                    label={translatedTextsObject.modeOfSubmission}
                    disabled={disabled}
                  />
                </CngGridItem>
              </Grid>
              <TemplateSelectDialogWithOptionEHBL
                manifestTemplateDialog={houseBillTemplateDialog}
                setManifestTemplateDialog={setHouseBillTemplateDialog}
                setConfirmDialog={setConfirmDialog}
                showNotification={showNotification}
                translatedTextsObject={translatedTextsObject}
                setValue={setValue}
                reset={reset}
                trigger={trigger}
                title={translatedTextsObject.houseBillTemplate}
              />
            </CardContent>
          </CngSection>
        </Grid>
      </Grid>
    </>
  )
}

function toClientDataFormat(serverData) {
  let localData = serverData;
  localData.responseDatetime = DateTimeFormatter.toClientDate(
    localData.responseDatetime
  );
  localData.mfDatetime = DateTimeFormatter.toClientDate(
    localData.mfDatetime
  );
  localData.printDate = DateTimeFormatter.toClientDate(
    localData.printDate
  );
  localData.storageDate = DateTimeFormatter.toClientDate(
    localData.storageDate
  );
  localData.submittedDate = DateTimeFormatter.toClientDate(
    localData.submittedDate
  );

  localData.isDangerousGoods = localData.isDangerousGoods == "Y" ? true : false
  localData.containerizedInd = localData.containerizedInd == "Y" ? true : false
  localData.consolidationInd = localData.consolidationInd == "Y" ? true : false

  return localData;
}

function toServerDataFormat(localData) {
  localData.responseDatetime = DateTimeFormatter.toServerDate(
    localData.responseDatetime
  );
  localData.mfDatetime = DateTimeFormatter.toServerDate(
    localData.mfDatetime
  );
  localData.printDate = DateTimeFormatter.toServerDate(
    localData.printDate
  );
  localData.storageDate = DateTimeFormatter.toServerDate(
    localData.storageDate
  );
  localData.submittedDate = DateTimeFormatter.toServerDate(
    localData.submittedDate
  );

  localData.isDangerousGoods = localData.isDangerousGoods == true ? "Y" : "N"
  localData.containerizedInd = localData.containerizedInd == true ? "Y" : "N"
  localData.consolidationInd = localData.consolidationInd == true ? "Y" : "N"
  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
