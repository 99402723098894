import React, { useRef,useState,useEffect } from 'react'
import { components } from 'cng-web-lib'
import FormProperties from './UNDGFormProperties'
import UNDGFieldsPlaceholder from './UNDGFieldsPlaceholder'
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form'
import _ from 'lodash'
import { Divider, Grid, Tooltip } from '@material-ui/core'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues } = FormProperties.formikProps

function UNDGSection(props) {

  const {undgCode} = props


  const { getValues, setValue } = useFormContext()

  function handleNewUndgCode(){
    let undgCode = {undgCodeValue:""}

    let undgCodeList = [...(getValues("undgCodeList") || []), undgCode]
    setValue("undgCodeList",undgCodeList)
  }

  function handleAddUndgCode(data) {
    let undgCode = [...(getValues("undgCodeList") || []), data]

    setValue("undgCodeList",undgCode)
  }

  function handleDeleteUndgCode(index) {
    const undgCode = [...getValues("undgCodeList")]

    undgCode.splice(index, 1)
    setValue("undgCodeList",undgCode)
  }

  function handleEditUndgCode(index, data) {

    const undgCode = [...getValues("undgCodeList")]

    undgCode[index] = data
    setValue("undgCodeList",undgCode)
  }

  function updateUndgCodeField(undgCode){
    let list = []
    for (var i = 0, l = undgCode.length; i < l; i++) {
      var obj = undgCode[i];
      list.push(obj.undgCodeValue)
    }

    setValue('undgCode', list.join("|"), { shouldDirty: true })
  }

  return (
    <Grid container spacing={1}>
      {_.isEmpty(undgCode) ? (
        <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs='auto'>
            <Tooltip placement='bottom' title='Add new'>
              <span>
                <CngIconButton
                  icon={['fal', 'plus']}
                  onClick={() => handleNewUndgCode()}
                  size='small'
                  type='outlined'
                />
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      ) : (
        undgCode.map((summary, index) => (
          <React.Fragment key={index}>
            <Grid item xs={12}>
              <UNDGEntry
                index={index}
                undgCodeList={summary}
                onAdd={handleAddUndgCode}
                onDelete={() => handleDeleteUndgCode(index)}
                onEdit={(index,data) => handleEditUndgCode(index, data)}
              />
            </Grid>
            {index + 1 !== undgCode.length && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
          </React.Fragment>
        ))
      )}
    </Grid>
  )
}

function UNDGEntry(props) {
  const { undgCodeList, onAdd, onDelete, onEdit,index } = props

  const methods = useForm({ defaultValues: undgCodeList })

  return (
    <FormProvider {...methods}>
      <Grid alignItems='center' container spacing={1}>
        <Grid item xs={12} sm>
          <FormProperties.Fields 
            onEdit={onEdit}
            methods={methods}
            index={index}
          />
        </Grid>
        <Grid item xs={12} sm='auto'>
            <Grid container spacing={1}>
              <Grid item xs='auto'>
                <Tooltip placement='bottom' title='Add'>
                  <span>
                    <CngIconButton
                      icon={['fal', 'plus']}
                      onClick={() => onAdd(initialValues)}
                      size='small'
                      type='outlined'
                    />
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs='auto'>
                <Tooltip placement='bottom' title='Clone'>
                  <span>
                    <CngIconButton
                      icon={['fal', 'copy']}
                      onClick={() =>
                        onAdd({ ...undgCodeList, id: undefined })
                      }
                      size='small'
                      type='outlined'
                    />
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs='auto'>
                <Tooltip placement='bottom' title='Delete'>
                  <span>
                    <CngIconButton
                      icon={['fal', 'trash']}
                      onClick={() => onDelete(undgCodeList)}
                      size='small'
                      type='outlined'
                    />
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default UNDGSection
