import React, { useEffect } from 'react'
import { components, constants, useTranslation } from 'cng-web-lib'
import FormProperties from './ContainerDetailsFormProperties'
import { Box, Grid } from '@material-ui/core'
import CngSection from 'src/components/cngcomponents/CngSection'
import CngField from 'src/components/cngcomponents/CngField'
import Namespace from 'src/constants/locale/Namespace'
import EhblHouseBillKeys from 'src/constants/locale/key/EhblHouseBill'

const {
  button: { CngButton, CngPrimaryButton },
  form: { CngForm },
  CngDialog
} = components

const { FormState } = constants

function ContainerDetailsDialog(props) {
  const {
    getLookupValue,
    getCountryStateLabel,
    onAddContainerDetails,
    onEditContainerDetails,
    isView,
    onClose,
    open,
    showNotification,
    containerDetails
  } = props

  const { initialValues, makeValidationSchema } = FormProperties.formikProps

  const { translate } = useTranslation(Namespace.EHBL_HOUSE_BILL)
  const translatedTextsObject = makeTranslatedTextsObject()
  function makeTranslatedTextsObject() {
    let containerDetails = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.TITLE
    )
    let hblId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.HBL_ID
    )
    let manifestId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.MANIFEST_ID
    )
    let mfId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.MF_ID
    )
    let equipmentId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.EQUIPMENT_ID
    )
    let equipmentType = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.EQUIPMENT_TYPE
    )
    let sealNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO
    )

    let sealNo1 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_1
    )

    let sealNo2 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_2
    )

    let sealNo3 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_3
    )

    let sealNo4 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_4
    )

    let sealNo5 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_5
    )

    let sealNo6 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_6
    )

    let sealNo7 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_7
    )

    let sealNo8 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_8
    )

    let sealNo9 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_9
    )

    let sealNo10 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_10
    )

    let sealNo11 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_11
    )

    let sealNo12 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_12
    )

    let sealNo13 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_13
    )

    let sealNo14 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_14
    )

    let sealNo15 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_15
    )

    let sealNo16 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_16
    )

    let sealNo17 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_17
    )

    let sealNo18 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_18
    )

    let sealNo19 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_19
    )

    let sealNo20 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_20
    )

    let sealNo21 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_21
    )

    let sealNo22 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_22
    )

    let sealNo23 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_23
    )

    let sealNo24 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_24
    )

    let sealNo25 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_25
    )

    let sealNo26 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_26
    )

    let sealNo27 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_27
    )

    let sealNo28 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_28
    )

    let sealNo29 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_29
    )

    let sealNo30 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_30
    )

    let sealNo31 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_31
    )

    let sealNo32 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_32
    )

    let sealNo33 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_33
    )

    let sealNo34 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_34
    )

    let sealNo35 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_35
    )

    let sealNo36 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_36
    )

    let sealNo37 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_37
    )

    let sealNo38 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_38
    )

    let sealNo39 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_39
    )

    let sealNo40 = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ContainerDetails.SEAL_NO_40
    )

    let updateButton = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UPDATE_BUTTON
    )

    let saveButton = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SAVE_BUTTON
    )

    return {
      containerDetails,
      hblId,
      manifestId,
      mfId,
      equipmentId,
      equipmentType,
      sealNo,
      sealNo1,
      sealNo2,
      sealNo3,
      sealNo4,
      sealNo5,
      sealNo6,
      sealNo7,
      sealNo8,
      sealNo9,
      sealNo10,
      sealNo11,
      sealNo12,
      sealNo13,
      sealNo14,
      sealNo15,
      sealNo16,
      sealNo17,
      sealNo18,
      sealNo19,
      sealNo20,
      sealNo21,
      sealNo22,
      sealNo23,
      sealNo24,
      sealNo25,
      sealNo26,
      sealNo27,
      sealNo28,
      sealNo29,
      sealNo30,
      sealNo31,
      sealNo32,
      sealNo33,
      sealNo34,
      sealNo35,
      sealNo36,
      sealNo37,
      sealNo38,
      sealNo39,
      sealNo40,
      updateButton,
      saveButton
    }
  }

  if (!open) {
    return null
  }

  if (isView && containerDetails) {

    if (containerDetails.sealNo != null && containerDetails.sealNo != undefined && containerDetails.sealNo != "") {
      let sealNoList = containerDetails.sealNo.split(":");

      let number = 0;
      if (sealNoList.length > number) {
        containerDetails.sealNo1 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo2 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo3 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo4 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo5 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo6 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo7 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo8 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo9 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo10 = sealNoList[number]
      }
      number++


      if (sealNoList.length > number) {
        containerDetails.sealNo11 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo12 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo13 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo14 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo15 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo16 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo17 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo18 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo19 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo20 = sealNoList[number]
      }
      number++


      if (sealNoList.length > number) {
        containerDetails.sealNo21 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo22 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo23 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo24 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo25 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo26 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo27 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo28 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo29 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo30 = sealNoList[number]
      }
      number++


      if (sealNoList.length > number) {
        containerDetails.sealNo31 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo32 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo33 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo34 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo35 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo36 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo37 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo38 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo39 = sealNoList[number]
      }
      number++

      if (sealNoList.length > number) {
        containerDetails.sealNo40 = sealNoList[number]
      }
      number++
    }
  }

  return (
    <CngDialog
      dialogContent={
        isView && containerDetails ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CngSection title={translatedTextsObject.trailerDTOTitle}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <CngField label={translatedTextsObject.equipmentId}>
                      {containerDetails.equipmentId}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CngField label={translatedTextsObject.equipmentType}>
                      {getLookupValue('equipmentType', containerDetails.equipmentType)}
                    </CngField>
                  </Grid>
                </Grid>
              </CngSection>
            </Grid>
            <Grid item xs={12}>
              <CngSection title='Seal No(s)'>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo1}>
                      {containerDetails.sealNo1}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo2}>
                      {containerDetails.sealNo2}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo3}>
                      {containerDetails.sealNo3}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo4}>
                      {containerDetails.sealNo4}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo5}>
                      {containerDetails.sealNo5}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo6}>
                      {containerDetails.sealNo6}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo7}>
                      {containerDetails.sealNo7}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo8}>
                      {containerDetails.sealNo8}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo9}>
                      {containerDetails.sealNo9}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo10}>
                      {containerDetails.sealNo10}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo11}>
                      {containerDetails.sealNo11}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo12}>
                      {containerDetails.sealNo12}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo13}>
                      {containerDetails.sealNo13}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo14}>
                      {containerDetails.sealNo14}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo15}>
                      {containerDetails.sealNo15}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo16}>
                      {containerDetails.sealNo16}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo17}>
                      {containerDetails.sealNo17}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo18}>
                      {containerDetails.sealNo18}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo19}>
                      {containerDetails.sealNo19}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo20}>
                      {containerDetails.sealNo20}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo21}>
                      {containerDetails.sealNo21}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo22}>
                      {containerDetails.sealNo22}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo23}>
                      {containerDetails.sealNo23}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo24}>
                      {containerDetails.sealNo24}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo25}>
                      {containerDetails.sealNo25}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo26}>
                      {containerDetails.sealNo26}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo27}>
                      {containerDetails.sealNo27}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo28}>
                      {containerDetails.sealNo28}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo29}>
                      {containerDetails.sealNo29}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo30}>
                      {containerDetails.sealNo30}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo31}>
                      {containerDetails.sealNo31}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo32}>
                      {containerDetails.sealNo32}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo33}>
                      {containerDetails.sealNo33}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo34}>
                      {containerDetails.sealNo34}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo35}>
                      {containerDetails.sealNo35}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo36}>
                      {containerDetails.sealNo36}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo37}>
                      {containerDetails.sealNo37}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo38}>
                      {containerDetails.sealNo38}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo39}>
                      {containerDetails.sealNo39}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo40}>
                      {containerDetails.sealNo40}
                    </CngField>
                  </Grid>
                </Grid>
              </CngSection>
            </Grid>
          </Grid>
        ) : (
          <CngForm
            fieldLevel='form'
            formikProps={{
              initialValues: containerDetails || initialValues,
              makeValidationSchema: makeValidationSchema,
              onSubmit: (data) => {
                if (containerDetails) {
                  onEditContainerDetails(data)
                } else {
                  onAddContainerDetails(data)
                }

                onClose()
              }
            }}
            formState={FormState.COMPLETED}
            renderBodySection={(labelMap, shouldHideMap) => (
              <Box padding={1}>
                <FormProperties.Fields
                  showNotification={showNotification}
                  shouldHideMap={shouldHideMap}
                />
              </Box>
            )}
            innerForm={true}
            renderButtonSection={() => (
              <Box display='flex' justifyContent='flex-end'>
                <CngPrimaryButton type='submit'>
                  {containerDetails
                    ? translatedTextsObject.updateButton
                    : translatedTextsObject.saveButton}
                </CngPrimaryButton>
              </Box>
            )}
          />
        )
      }
      dialogAction={
        isView ? <CngButton onClick={onClose}>OK</CngButton> : null
      }
      dialogTitle={translatedTextsObject.containerDetails}
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='lg'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default ContainerDetailsDialog
