import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
import EhblHouseBillKeys from 'src/constants/locale/key/EhblHouseBill'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  const errMsgAlphaNumeric = translate(
    Namespace.EHBL_HOUSE_BILL,
    EhblHouseBillKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC
  )
  const errMsgAlphaNumericWithSpecialChar = translate(
    Namespace.EHBL_HOUSE_BILL,
    EhblHouseBillKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_WITH_SPECIAL_CHAR
  )

  const errMsgAlphaNumericWithSpecialCharNoSpace = translate(
    Namespace.EHBL_HOUSE_BILL,
    EhblHouseBillKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_WITH_SPECIAL_CHAR_NO_SPACE
  )

  const errMsgAlphaNumericWithHypen = translate(
    Namespace.EHBL_HOUSE_BILL,
    EhblHouseBillKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_WITH_HYPEN
  )

  const errMsgNumeric = translate(
    Namespace.EHBL_HOUSE_BILL,
    EhblHouseBillKeys.ValidationMessage.ERROR_MSG_NUMERIC
  )

  const errMsgMinLength = translate(
    Namespace.EHBL_HOUSE_BILL,
    EhblHouseBillKeys.ValidationMessage.ERROR_MSG_MIN_LENGTH
  )
  const errMsgMaxLength = translate(
    Namespace.EHBL_HOUSE_BILL,
    EhblHouseBillKeys.ValidationMessage.ERROR_MSG_MAX_LENGTH
  )

  const regexNumeric = '^[0-9]*$'
  const regexAlphaNumeric = '^[a-zA-Z0-9]*$'
  const regexAlphaNumericWithHypen = '^[a-zA-Z0-9-]*$'
  const regexAlphaNumericWithSpecialChar =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'

  const regexAlphaNumericWithSpecialCharNoSpace =
    '^[a-zA-Z0-9,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\]+$'

  return Yup.object({
    hblId: Yup.string().nullable(),
    manifestId: Yup.string().nullable(),
    mfId: Yup.string().nullable(),
    corpid: Yup.string().nullable(),
    partyId: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(35, errMsgMaxLength + " 35"),
    partyName: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(70, errMsgMaxLength + " 70"),
    partyType: Yup.string().nullable(),
    division: Yup.string().nullable(),
    locPortCode: Yup.string().nullable(),
    poBoxNo: Yup.string().nullable(),
    address: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(105, errMsgMaxLength + " 105"),
    city: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(20, errMsgMaxLength + " 20"),
    stateCode: Yup.string().nullable(),
    countryCode: Yup.string().nullable(),
    postalCode: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(9, errMsgMaxLength + " 9"),
    contactName: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(70, errMsgMaxLength + " 70"),
    telephoneNum: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(35, errMsgMaxLength + " 35"),
    snpType: Yup.string().nullable(),
    snpUcn: Yup.string().nullable(),
    snpB2bComment: Yup.string().nullable(),
    snpIdentifierValue: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15")
  })
}

export default makeValidationSchema
